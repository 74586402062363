.page-content {
    @extend %clearfix;
}

// hover-line
.hover-line:hover {
    rect:nth-of-type(1) {
        -webkit-animation: line-dn 0.5s linear infinite alternate;
        -moz-animation: line-dn 0.5s linear infinite alternate;
        -o-animation: line-dn 0.5s linear infinite alternate;
        animation: line-dn 0.5s linear infinite alternate;
    }
    rect:nth-of-type(3) {
        -webkit-animation: line-dn2 0.5s linear infinite alternate;
        -moz-animation: line-dn2 0.5s linear infinite alternate;
        -o-animation: line-dn2 0.5s linear infinite alternate;
        animation: line-dn2 0.5s linear infinite alternate;
    }
}

/* Line-dn */
@-webkit-keyframes line-dn {
    from   { width: 34px; x: 0px;}
    to { width: 11.3334px; x: 11px;}
}
@-moz-keyframes line-dn {
    from   { width: 34px; x: 0px;}
    to { width: 11.3334px; x: 11px;}
}
@-o-keyframes line-dn {
    from   { width: 34px; x: 0px;}
    to { width: 11.3334px; x: 11px;}
}
@keyframes line-dn {
    from   { width: 34px; x: 0px;}
    to { width: 11.3334px; x: 11px;}
}

/* Line-dn2 */
@-webkit-keyframes line-dn2 {
    from   { width: 11.3334px; x: 11.0503px;}
    to { width: 34px; x: 0px;}
}
@-moz-keyframes line-dn2 {
    from   { width: 11.3334px; x: 11.0503px;}
    to { width: 34px; x: 0px;}
}
@-o-keyframes line-dn2 {
    from   { width: 11.3334px; x: 11.0503px;}
    to { width: 34px; x: 0px;}
}
@keyframes line-dn2 {
    from   { width: 11.3334px; x: 11.0503px;}
    to { width: 34px; x: 0px;}
}

/* HOME 01 */
.home-01 {
    .page-content {
        overflow: hidden;
    }
}
.home-01,
.home-02 {
    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
    }
}

.header.white-style {
    #primary-menu .menu > ul > li > a,
    #primary-menu .menu > ul > li:hover:before, #primary-menu .menu > ul > li.active:before,
    #primary-menu .menu > ul > li:hover:after, #primary-menu .menu > ul > li.active:after,
    .mini-cart_button,
    .search-icon a {
        color: $white-color;
    }
    #primary-menu .sub-menu,
    .cart-list{
        border-top-color: $white-color;
    }
}

.new-update {
    padding-bottom: 30px;
    .blog-entry-title a {
        margin: 30px 0px;
    }
    .blog-entry-content p {
        margin-bottom: 30px;
    }
    .post {
        margin-bottom: 70px;
    }
}

@include media-breakpoint-down(sm) {
    .home-01, .home-02 {
        .logo {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
    .home-01 .hover-line h1 {
        font-size: 36px;
    }
}

@include media-breakpoint-up(lg) {
    .we-are-results {
        max-width: 550px;
        padding-left: 30px;
    }
    .we-can-do {
        max-width: 600px;
        float: right;
        padding-right: 75px;
    }
}

/* HOME 02 */
.home-02 {
    .slide-caption {
        @include center-y;
        left: 10%;
        text-align: center;
        margin-top: 30px;
    }
}
@include media-breakpoint-up(lg) {
    .home-02 {
        .icon-boxes {
            max-width: 600px;
        }
        .section-5 {
            overflow: hidden;
        }
        .section-5_text {
            max-width: 500px;
            &:before {
                content: '';
                display: block;
                width: 536px;
                height: 1000px;
                position: absolute;
                top: -400px;
                left: 125px;
                background: #f7f7f7;
                z-index: -1;
            }
        }
    }
}

/* HOME 03 */
.home-03 {
    .header-wrapper {
        min-height: 400px;
        background: url("../images/home/home3-section1.png") top left;
    }
    .slide-caption {
        padding: 50px 0px 30px;
    }
    .slide-caption h2 {
        margin-bottom: 30px;
    }
    .line-vertical {
        background: #bbb;
    }
    .home3-section1 {
        .icon-boxes {
            padding-top: 20px;
            min-height: 400px;
        }
    }
    .we-bring-you {
        padding: 70px 15px;
        max-width: 662px;
        .bttn {
            margin-bottom: 10px;
            margin-right: 6px;
        }
    }
    .home3-section1_img {
        margin-top: -30px;
    }
    .progress-img {
        width: 100%;
        text-align: center;
        margin-top: 70px;
    }

}

@include media-breakpoint-up(sm) {
    .home-03 {
        .slide-caption {
            padding: 120px 0px 30px;
        }
    }
}

@include media-breakpoint-up(md) {
    .home-03 {
        .slide-caption {
            padding: 150px 0px 30px;
        }
        .slide-caption h2 {
            margin-bottom: 30px;
            font-size: 50px;
        }
        .line-vertical {
            height: 160px;
        }
        .home3-section1 {
            &_img {
                position: absolute;
                top: 50%;
                left: -140px;
                margin-top: -240px;
                z-index: 9;
            }
        }
        .home3-section1 {
            .icon-boxes {
                padding-right: 150px;
                padding-left: 20px;
                min-height: 400px;
            }
        }
        .we-bring-you {
            padding: 0px 0px 0px 150px;
            max-width: 662px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .home-03 {
        .slide-caption {
            padding: 180px 0px 50px;
        }
        .slide-caption h2 {
            margin-bottom: 30px;
            font-size: 80px;
        }
        .home3-section1 {
            .icon-boxes {
                padding-right: 180px;
            }
        }
        .we-bring-you {
            padding-left: 180px;
        }

        .progress-box {
            position: relative;
            padding-bottom: 150px;
        }
        .progress-item {
            padding-right: 30px;
        }
        .progress-img {
            width: 851px;
            position: absolute;
            bottom: 0;
            left: 50%;
            z-index: 3;
        }
        .section-5 {
            position: relative;
            margin-top: -110px;
            z-index: 2;
            background: $white-color;
        }
    }
}

/* HOME 04 */
.home-04 {
    .intro {
        background: url("../images/home/home4-section1-bg.png") top center;
        background-size: cover;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .intro-title {
        text-transform: inherit;
        font-size: 48px;
        padding-left: 20px;
    }
    .intro-item {
        @extend %clearfix;
        &_number {
            font-size: 70px;
            width: 70px;
            text-align: center;
            color: $black-color;
            float: left;
            line-height: 1;
            margin-top: -15px;
        }
        &_content {
            margin-left: 70px;
            h5 {
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 15px;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .home-04 {
        .header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 9999;
        }
    }
}

@include media-breakpoint-up(lg) {
    .home-04 {
        .intro-title {
            padding-left: 0px;
            margin-left: -50px;
            .space {
                padding-left: 70px;
            }
        }
    }
}

/* HOME 05 */
.home-05 {
    .page-content,
    .header,
    .footer {
        padding-left: 50px;
        padding-right: 50px;
    }
    .copy-right {
        text-transform: uppercase;
        color: $black-color;
        padding: 45px 15px 40px;
        text-align: center;
    }

    .page-content {
        position: relative;
        &:before,
        &:after {
            content: '';
            display: block;
            width: 50px;
            height: 2px;
            background: #ccc;
            position: absolute;
            top: 50%;
            margin-top: 10px;
            z-index: 9999;
        }
        &:before {
            left: 40px;
        }
        &:after {
            right: 40px;
        }
    }
    .section-name,
    .section-order {
        text-transform: uppercase;
        color: $black-color;
        font-size: 18px;
        position: absolute;
        @include center-y;
        line-height: 1em;
        white-space: nowrap;
        display: inline-block;
        width: 200px;
        text-align: center;
    }
    .section-name {
        @include rotate(-90deg);
        right: calc(100% - 120px);
    }
    .section-order {
        @include rotate(90deg);
        left: calc(100% - 120px);
    }

    .icon-boxes {
        background: #f7f7f7;
        padding: 50px 20px;
        margin-top: 30px;
        &_icon {
            color: #111;
            font-size: 30px;
        }
    }

    .our-works {
        @extend %clearfix;
    }
    .our-works_item {
        float: left;
        width: 100%;
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #111;
            opacity: 0;
            visibility: hidden;
            @extend %transit;
        }
        img {
            width: 100%;
        }
    }
    .our-works_item:hover {
        &:before {
            opacity: 0.8;
            visibility: visible;
        }
        .our-works_overlay {
            opacity: 1;
            visibility: visible;
        }
    }
    .our-works_overlay {
        @include center-y;
        @extend %transit;
        width: 100%;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        a {
            color: #fff;
            font-weight: 400;
        }
    }

    .get-in-touch {
        background: #f7f7f7;
    }
}
@include media-breakpoint-down(xs) {
    .home-05 .icon-boxes_icon {
        padding-right: 0;
    }
}

@include media-breakpoint-up(sm) {
    .home-05 {
        .our-works_item {
            width: 20%;
        }
    }
}

@include media-breakpoint-up(md) {
    .home-05 {
        .page-content,
        .header,
        .footer {
            padding-left: 100px;
            padding-right: 100px;
        }
        .section-name {
            right: calc(100% - 150px);
        }
        .section-order {
            left: calc(100% - 150px);
        }
        .page-content {
            &:before,
            &:after {
                width: 80px;
            }
            &:before {
                left: 80px;
            }
            &:after {
                right: 80px;
            }
        }

        .digital-specialist {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .who-we-are {
            &:before {
                content: '';
                display: block;
                width: 30%;
                height: 100%;
                background: #f7f7f7;
                position: absolute;
                top: 0;
                right: 100px;
            }
        }

        .what-we-do {
            padding-top: 100px;
            padding-bottom: 100px;
            &:before {
                content: '';
                display: block;
                width: 20%;
                height: 100%;
                background: #f7f7f7;
                position: absolute;
                top: 0;
                left: 100px;
            }
        }
        .effective {
            padding-left: 80px;
        }
        .icon-boxes {
            background: #f7f7f7;
            padding: 100px 50px;
        }

        .our-works_item {
            width: 20%;
        }

        .get-in-touch {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .home-05 {
        .who-we-are {
            &:before {
                width: 38%;
            }
        }
        .get-in-touch {
            padding-top: 200px;
            padding-bottom: 200px;
        }
    }
}

/* HOME 06 */
.home-06 {
    .header {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
    }
    .svg-plus {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
.intro-text {
    padding: 100px 20px;
    h2 {
        line-height: 1.6em;
        font-size: 24px;
    }
    .read-more {
        color: $white-color;
        text-decoration: underline;
    }
}

@include media-breakpoint-up(md) {
    .intro-text {
        padding: 250px 20px;
        h2 {
            line-height: 1.6em;
            font-size: 36px;
        }
        .read-more {
            color: $white-color;
            text-decoration: underline;
        }
    }
    .home-06 {
        .folio-main-item {
            padding-left: 35px;
            padding-right: 35px;
        }

        .folio-title {
            color: $black-color;
            &:hover {
                color: $primary-color;
            }
        }

    }
}

/* HOME 07 */
.home-07 {
    .section-1 {
        overflow: hidden;
    }
    .effective {
        background: #f7f7f7;
        padding: 70px 50px;
    }
    .img-sec1-1 {
        position: relative;
        margin-bottom: -30px;
        z-index: 2;
    }
    .img-sec1-2 {
        position: relative;
        margin-left: -50px;
        z-index: 2;
    }
    .digital-specialist {
        .bttn {
            margin: 0px 6px 10px 0px;
        }
    }
}

@include media-breakpoint-up(md) {
    .home-07 {
        .header {
            padding-left: 35px;
            padding-right: 35px;
        }
        footer {
            margin-left: 50px;
            margin-right: 50px;
        }
        .page-content {
            padding-left: 50px;
            padding-right: 50px;
        }
        .folio-main-3col {
            margin-left: -30px;
            margin-right: -30px;
        }
        .digital-specialist {
            position: relative;
            &:before {
                content: '';
                display: block;
                width: 80%;
                height: 250%;
                top: -90%;
                left: 35%;
                position: absolute;
                background: #f7f7f7;
                z-index: -1;
            }
        }
        .effective {
            padding: 100px 80px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .home-07 {
        .folio-main-3col {
            margin-left: -50px;
            margin-right: -50px;
        }
        .folio-main-grid .folio-main-item {
            padding-left: 35px;
            padding-right: 35px;
            margin-bottom: 70px;
        }
    }
}

/* HOME 08 */
.home-08 {
    .slider {
        background: url("../images/home/home8-section1.jpg") center center;
        padding: 150px 0px;
        h2 {
            font-size: 50px;
            font-weight: 600;
            text-transform: inherit;
            padding-left: 12%;
        }
    }
    .see-more-work {
        background: url("../images/home/home8-see-more-bg.jpg") center center;
    }
}

@include media-breakpoint-up(sm) {
    .home-08 {
        .slider {
            padding-top: 150px;
            padding-bottom: 150px;
            h2 {
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .home-08 {
        .slider {
            padding-top: 250px;
            padding-bottom: 250px;
            h2 {
                font-size: 70px;
            }
        }
        .folio-main-grid .folio-main-item {
            padding: 0px 35px;
            margin-bottom: 70px;
        }
    }
}

/* HOME 09 */
.home-09 {
    .detail-wrapper {
        position: relative;
        min-height: 700px;
        background-size: cover;
    }
    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .detail-content {
        @include center-y;
        left: 50%;
        margin-left: -145px;
        width: 290px;
        background: $white-color;
        text-align: center;
        padding: 50px 20px;
        &_tag {
            margin-bottom: 20px;
        }
        &_tag a {
            color: $black-color;
            &:hover {
                color: $primary-color;
            }
        }
        &_title {
            line-height: 1.4em;
            padding: 50px 0px;
            font-size: 32px;
        }
    }
}
.line-short {
    width: 40px;
    height: 3px;
    background: $primary-color;
    margin: 0px auto;
}

@include media-breakpoint-up(sm) {
    .home-09 {
        .detail-wrapper {
            min-height: 800px;
        }
        .detail-content {
            width: 400px;
            margin-left: -200px;
            padding: 30px;
            &_title {
                padding: 70px 0px;
                font-size: 36px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .home-09 {
        .detail-content {
            margin-left: -250px;
            width: 500px;
            padding: 60px;
            &_title {
                padding: 70px 0px;
                font-size: 48px;
            }
        }
    }
}

/* HOME 10 */
.home-10 {
    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
    }
    .shop-banner {
        margin-top: 30px;
        position: relative;
        &_content {
            @include center-y;
            width: 100%;
            text-align: center;
            padding-left: 50%;
            h3 {
                font-weight: 500;
                margin-bottom: 5px;
            }

        }
        &_link:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            @extend %transit;
        }
    }
}

/* HOME 12 */
.home-12 {
    .header-wrapper {
        position: relative;
    }
    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
    }
}
