.social-menu {
    .menu {
        li {
            display: inline-block;
            width: auto !important;
            &:before {
                display: none;
            }
            a {
                font-size: 0;
                color: $black-color;
                &:hover {
                    color: $primary-color;
                }
                &:before {
                    display: inline-block;
                    font-size: 18px;
                    @extend %icon;
                }
                &[href*="facebook"]:before {
                    content: "\f09a";
                }
                &[href*="twitter"]:before {
                    content: "\f099";
                }
                &[href*="instagram"]:before {
                    content: "\f16d";
                }
                &[href*="youtube"]:before {
                    content: "\f16a";
                }
                &[href*="plus.google.com"]:before {
                    content: "\f0d5";
                }
                &[href*="flickr"]:before {
                    content: "\f16e";
                }
                &[href*="dribbble"]:before {
                    content: "\f17d";
                }
                &[href*="foursquare"]:before {
                    content: "\f180";
                }
                &[href*="linkedin"]:before {
                    content: "\f0e1";
                }
                &[href*="tumblr"]:before {
                    content: "\f173";
                }
                &[href*="feed"]:before {
                    content: "\f09e";
                }
                &[href*="pinterest"]:before {
                    content: "\f0d2";
                }
            }
        }
    }
}
