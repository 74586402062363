.page-contact {
    .social-menu .menu li {
        margin-right: 10px;
        a {
            color: #444;
            &:before {
                font-size: 18px;
            }
            &:hover {
                color: $primary-color;
            }
        }
    }
}

.map {
    display: block;
    width: 100%;
    height: 570px;
    @include media-breakpoint-down(md) {
        height: 450px;
    }
    @include media-breakpoint-down(sm) {
        height: 300px;
    }
}
