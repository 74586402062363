.page-our-services {
    .page-header {
        background: url("../images/our-services/our-services_page-header_bg.jpg") top left;
        background-size: cover;
        height: 800px;
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #000;
            opacity: 0.85;
        }

        &_content {
            @include center-y;
            width: 100%;
        }
        .heading-5 {
            margin-top: 65px;
            margin-bottom: 25px;
            color: $white-color;
        }
        h2 {
            font-size: 60px;
            color: $white-color;
        }
    }
}

.our-services_banner-01 {
    background: url("../images/our-services/our-services_section-02_bg.jpg") top left;
    height: 700px;
    position: relative;
    &_wrapper {
        @include center-y;
        width: 100%;
    }
    h2 {
        color: $white-color;
        text-transform: inherit;
        font-size: 60px;
        margin-top: 50px;
        margin-bottom: 35px;
        @include media-breakpoint-down(sm) {
            font-size: 40px;
        }
        span {
            color: $primary-color;
        }
    }
    p {
        color: $white-color;
        max-width: 700px;
        margin: 0 auto;
    }
}

.work-process_item {
    padding-left: 80px;
    padding-top: 50px;
    margin-bottom: 30px;
    &-number {
        font-size: 48px;
        font-weight: 700;
        color: $primary-color;
        width: 114px;
        height: 114px;
        border: 1px solid #eee;
        display: block;
        text-align: center;
        line-height: 114px;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    &-title {
        margin-bottom: 20px;
        display: inline-block;
    }
}

@include media-breakpoint-up(md) {
    .work-process_item {
        overflow: hidden;
        &-title {
            position: relative;
            &:before {
                content: '';
                width: 200px;
                height: 1px;
                background: #ddd;
                display: block;
                position: absolute;
                top: 50%;
                right: -230px;
            }
        }
    }

    .work-process_item-wrapper:last-child {
        .work-process_item-title:before {
            display: none;
        }
    }
}

