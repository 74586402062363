// COUNTER
.counter-box {
    padding: 90px 0px;
    h2 {
        margin-bottom: 10px;
    }
}

.counter-box.style-1 {
    background: url("../images/counter_bg1.jpg") top left;
    h2 {
        color: $primary-color;
    }
}

.counter-box.style-2 {
    background: $secondary-color url("../images/counter_bg2.jpg") top left;
    h2, h5 {
        color: $white-color;
    }
}

@include media-breakpoint-down(xs) {
    .counter-box {
        padding-bottom: 55px;
    }
    .counter-item {
        margin-bottom: 35px;
    }
}
