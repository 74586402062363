// QUOTE
.quote {
    background: url("../images/testimonial_bg_skin1.jpg") top left;
    background-size: cover;
    min-height: 500px;
    padding: 115px 0px;
}

.quote-item {
    &_icon {
        font-size: 60px;
        color: $primary-color;
        margin-bottom: 20px;
    }
    &_text {
        color: $white-color;
        font-size: 18px;
        line-height: 1.7em;
        margin-bottom: 30px;
    }
    .author {
        span {
            text-transform: uppercase;
            color: $primary-color;
            display: block;
        }

    }
}

// TESTIMONIALS
.testimonials.skin_bg-primary-color {
    background: url("../images/testimonial_bg_skin2.jpg") top left;
    background-size: cover;
    padding: 100px 0px;
}

.testimonials.style-03 {
    background: url("../images/home/home7-testi-bg.jpg") top left;
    background-size: cover;
    padding: 100px 0px;
}

.testimonials {
    img {
        position: relative;
        margin-top: -85px;
    }
    .testi-talk {
        i, p {
            color: $white-color;
        }
        i {
            margin-bottom: 0;
            font-size: 60px;
        }
        p {
            font-size: 32px;
            font-weight: 300;
            line-height: 1.2em;
            letter-spacing: 0.03em;
        }
        text-align: center;
        margin: 0 auto 30px;
    }

    .quote-item {
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        &_wrapper {
            background: $white-color;
            border-radius: 6px;
            text-align: center;
            padding: 50px 30px;
        }
        &_text {
            color: $gray-color;
            font-size: 16px;
        }
        .author {
            font-style: inherit;
            font-size: 12px;
            span {
                font-size: 14px;
            }
        }
        &:before {
            content: '';
            @include arrow(left, $white-color, 17.5px 15px 17.5px 0px);
            position: absolute;
            top: calc(50% - 7.5px);
            left: 0px;
        }
    }
    .owl-dots {
        position: absolute;
        top: 50%;
        right: 0px;
        margin-top: -20px;
    }
    .owl-dot {
        display: block;
        margin-bottom: 5px;
    }
}

.owl-carousel .owl-item img {
    width: auto;
}

.owl-dot {
    width: 10px;
    height: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    display: inline-block;
    &.active {
        background: $white-color;
        border-color: $white-color;
    }
}

@include media-breakpoint-up(sm) {
    .quote-item {
        &_text {
            font-size: 24px;
        }
    }
}

@include media-breakpoint-up(md) {
    .testimonials {
        .owl-dots {
            right: -30px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .testimonials.skin_bg-primary-color {
        padding: 0;
        .testi-talk {
            position: absolute;
            top: 50%;
            left: -72px;
            margin-top: -80px;
        }
    }
    .testimonials {
        padding: 0;
        .testi-talk {
            p {
                font-size: 34px;
            }
        }

        .quote-item_wrapper {
            padding: 75px 60px;
        }
        .quote-item_text {
            font-size: 18px;
        }
    }
}
