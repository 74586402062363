.title-bg {
    position: relative;
    overflow-x: hidden;
}

.title-bg_content {
    @include center-y;
    width: 100%;
    h2 {
        margin-top: 35px;
        margin-bottom: 20px;
        color: $white-color;
    }
}

.title-bg.style-01 {
    background: url("../images/home/home3-section4_bg.jpg") top left;
    background-size: cover;
    min-height: 500px;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #111;
        opacity: 0.9;
    }
}

.title-bg.style-02 {
    background: url("../images/home/home4-title-bg2.jpg") center center;
    background-size: cover;
    min-height: 600px;
    h2 {
        font-size: 60px;
        margin-bottom: 30px;
        @include media-breakpoint-down(xs) {
            font-size: 32px;
        }
    }
}
