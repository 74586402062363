// ----------------------------------------------------------------------
// Bootstrap Grid
// ----------------------------------------------------------------------
// Includes relevant variables and mixins for the regular (non-flexbox) grid
// system, as well as the generated predefined classes (e.g., `.col-4-sm`).
$enable-flex: true;
$enable-shadows: true;
$enable-gradients: true;
$enable-transitions: true;
$font-size-root: 10px;
$link-hover-decoration: none;

// Grid system
// Define your custom responsive grid.
$grid-breakpoints: (
    // Extra small screen / phone / 320px
    xs: 20em,
    // Small screen / phone / 768px
    sm: 48em,
    // Medium screen / tablet / 1024px
    md: 64em,
    // Large screen / desktop / 1280px
    lg: 80em,
    // Extra large screen / wide desktop / 1920px
    xl: 120em
);

// Number of columns in the grid.
$grid-columns: 12;

// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 3rem;

// Container sizes
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 64rem, // 640
    md: 96rem, // 960
    lg: 117rem, // 1170
);

// ----------------------------------------------------------------------
// Font
// ----------------------------------------------------------------------
// Font size
$regularSize: 14;

// Font Families
$poppins: 'Poppins', sans-serif;
$lato: 'Lato', sans-serif;
$montserrat: 'Montserrat', sans-serif;

// ----------------------------------------------------------------------
// Color
// ----------------------------------------------------------------------
$primary-color: #00aeef;
$secondary-color: #0076a3;
$white-color: #fff;
$black-color: #111;
$gray-color: #999;
$dark-gray-color: #444;
$light-gray-color: #f5f5f5;

$brand-success: #e0f2d9;
$brand-info: #ecf1f9;
$brand-warning: #fdf9df;
$brand-danger: #ffecec;

$sale-color: #e95249;
$price-red-color: #e95249;

// ----------------------------------------------------------------------
// Space
// ----------------------------------------------------------------------
$space-xlarge: 90px;
$space-large: 70px;
$space-medium: 50px;
$space-small: 30px;
$space-xsmall: 20px;
