.header-wrapper {
    position: relative;
}
.logo {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mini-cart {
    margin-top: 5px;
}

.search-icon {
    margin-left: 30px;
    margin-top: 5px;
}

.mobile-menu-btn {
    margin-left: 30px;
    margin-top: 5px;
}
