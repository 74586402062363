.icon-boxes {
    &_icon, &_content {
        display: table-cell;
        vertical-align: top;
    }
    &_icon {
        font-size: 40px;
        color: $black-color;
        position: relative;
        padding: 5px 20px 0px 0px;
        color: #ccc;
    }
    &_content {
        padding-top: 10px;
        padding-bottom: 15px;
        h5, p {
            margin-bottom: 15px;
        }
    }
    &.reverse {
        text-align: right;
        .icon-boxes_icon {
            padding-right: 0;
            padding-left: 20px;
        }
    }
}

.icon-boxes.style-02 {
    .icon-boxes_icon, .icon-boxes_content {
        vertical-align: middle;
    }
    .icon-boxes_icon {
        font-size: 60px;
    }
}

.icon-boxes.style-line {
    padding-left: 70px;
    .icon-boxes_icon {
        &:before {
            content: '';
            width: 90px;
            height: 1px;
            position: absolute;
            right: calc(100% + 20px);
            top: 50%;
            background: #eee;
        }
    }
}
