// Lists Style
.dione-list ol li,
.dione-list ul li {
    line-height: 2.5em;
    margin-left: 30px;
}

.dione-list.type1 li,
.dione-list.type2 li,
.dione-list.type3 li {
    position: relative;
    &:before {
        @extend %icon-pe-7s;
        position: absolute;
        font-size: 15px;
        top: 50%;
        left: -25px;
        margin-top: -7px;
    }
}

.dione-list.type1 li:before {
    content: "\e685";
}

.dione-list.type2 li:before {
    content: "\e684";
}

.dione-list.type3 li:before {
    content: "\e66c";
}

.dione-list.type4 li {
    list-style-type: square;
}

.dione-list.type5 li {
    list-style-type: disc;
}

.dione-list.type6 li {
    list-style-type: decimal;
}
