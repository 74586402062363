// MENU CSS Base
.menu,
.menu li {
    position: relative;
}

.menu > ul > li {
    display:inline-block;
}

.menu > ul > li > a {
    display:inline-block;
}

.menu li:hover > ul {
    opacity:1;
    visibility: visible;
}

.sub-menu {
    width:200px;
    position:absolute;
    top: 100%;
    left:0px;
    opacity:0;
    visibility: hidden;
    z-index: 9999;
    box-shadow:0px 2px 3px rgba(0,0,0,0.2);
    background:#fff;
    text-align: left;
}

.sub-menu li > .sub-menu {
    top: 0;
    left: 100%;
}

.sub-menu li {
    display:block;
}

.sub-menu li a {
    display:block;
}

li.mega-menu {
    position: static;
    .sub-menu {
        width: 100%;
    }
}

// PRIMARY STYLE
#primary-menu {
    .menu > ul > li {
        padding: 15px 12px;
        margin: 0 13px;
        color: $black-color;
        & > a {
            color: $black-color;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            position: relative;
            &:before, &:after {
                position: absolute;
                top: 0;
                opacity: 0;
                visibility: hidden;
                @extend %transit;
            }
            &:before {
                content: '[';
                left: -15px;
            }
            &:after {
                content: ']';
                right: -15px;
            }
        }

        &:hover,
        &.active {
            & > a:before, & > a:after {
                opacity: 1;
                visibility: visible;
            }
            & > a:before {
                left: -10px;
            }
            & > a:after {
                right: -10px;
            }
        }
        &:hover > .sub-menu {
            margin-top: 0;
        }
        & > .sub-menu {
            margin-top: 30px;
        }
    }
    .menu > ul > li.no-before-after {
        &:before, &:after {
            display: none;
        }
    }

    .sub-menu {
        padding: 20px 0px;
        border-top: 2px solid $black-color;
        @extend %transit;
    }
    .sub-menu li a {
        padding: 10px 20px;
        text-transform: capitalize;
        color: $black-color;
        &:hover {
            color: $primary-color;
        }
    }
    .sub-menu > li > .sub-menu {
        margin-left: 30px;
        margin-top: 0;
        top: -22px;
    }
    .sub-menu > li:hover > .sub-menu {
        margin-left: 0;
    }

    .sub-menu .has-child {
        &:after {
            @extend %icon-pe-7s;
            @extend %transit;
            @include center-y;
            content: '\e684';
            position: absolute;
            right: 10px;
            font-size: 20px;
        }
        &:hover {
            &:after {
                color: $primary-color;
            }
        }
    }
}
