/* PAGE-HEADER */
.page-blog-grid-boxed,
.page-blog-grid-fullwidth {
    .page-header {
        border-bottom: none;
        margin-top: 120px;
        margin-bottom: 60px;
        h2 {
            text-transform: inherit;
            margin-top: 35px;
            margin-bottom: 30px;
        }
    }
}

/* TAG CLOUD */
.tagcloud a {
    border: 1px solid #eee;
    display: inline-block;
    padding: 9px 17px;
    border-radius: 3px;
    margin-bottom: 10px;
    margin-right: 5px;
    &:hover {
        border-color: $primary-color;
        color: $primary-color;
    }
}

/* BLOG-ENTRY-CONTENT */
.blog-entry {
    margin-bottom: 30px;
    @extend %clearfix;
    &-title a {
        color: $black-color;
        margin: 15px 0px;
        display: block;
        line-height: 1.3em;
    }
    &-content p {
        margin-bottom: 15px;
    }
    .blog-quote {
        background: $black-color;
        border: none;
        font-size: 22px;
        color: $white-color;
        padding: 41px;
        text-align: center;
        span {
            display: block;
            color: $primary-color;
            font-size: 14px;
            text-transform: uppercase;
            margin-top: 15px;
        }
    }
    .date {
        font-size: 12px;
    }
    .author {
        color: $black-color;
        @extend .heading-6;
        img {
            margin-right: 5px;
            border-radius: 50%;
        }
    }
    .postcontent-grid {
        background: $white-color;
        padding: 35px
    }
}
.post-thumb img {
    width: 100%;
}

.fluid-width-video-wrapper,
.fluid-width-media-wrapper {
    width: 100%;
    position: relative;
    padding-top: 56.25%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

.fluid-width-media-wrapper {
    padding-top: 40%;
}

@include media-breakpoint-down(sm) {
    .page-blog-grid-fullwidth {
        .sidebar {
            margin-top: 50px;
        }
    }
}

/* BLOG GRID LAYOUT */
.page-blog-grid-fullwidth, .page-blog-grid-boxed {
    .blog-entry-wrapper {
        background: $light-gray-color;
        overflow: hidden;
        padding: 30px;
    }
}

/* BLOG SINGLE */
.page-blog-single {
    .blog-entry {
        .post-thumb {
            margin-bottom: 50px;
        }
        &-title {
            text-transform: inherit;
            margin-top: 15px;
            margin-bottom: 50px;
        }
        blockquote {
            margin-top: 35px;
            margin-bottom: 40px;
        }

        &-footer {
            border-top: 1px solid #ddd;
            margin-top: 40px;
            padding-top: 30px;
            padding-bottom: 30px;
            h5 {
                display: inline-block;
                font-size: 12px;
                margin-right: 20px;
            }
            .social-menu {
                display: inline-block;
            }
            .tags a {
                font-size: 12px;
            }
        }
    }

    .author-info {
        background: $light-gray-color;
        padding: 40px;
        &_avatar {
            margin-bottom: 20px;
        }
        .author-name {
            @extend .heading-5;
            text-transform: uppercase;
            color: $black-color;
            margin-bottom: 15px;
        }
        .author-text {
            margin-bottom: 15px;
        }
    }

    .social-menu li {
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .page-blog-single {
        .col-md-9 {
            padding-left: 0;
        }
        .col-md-3 {
            padding-right: 0;
        }
        .author-info {
            &_avatar {
                float: left;
                margin-right: 20px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
}

/* POST NAVIGATION */
.post-navigation {
    padding: 45px 0px;
    .nav-previous a, .nav-next a {
        position: relative;
        &:before {
            @extend %icon-pe-7s;
            font-size: 30px;
            position: absolute;
            top: 50%;
            margin-top: -15px;
        }
    }
    .nav-previous a {
        padding-left: 15px;
        &:before {
            content: '\e686';
            left: -8px;
        }
    }
    .nav-next a {
        padding-right: 15px;
        &:before {
            content: '\e684';
            right: -11px;
        }
    }
}

/* COMMENTS */
.comment-area {
    background: $light-gray-color;
    padding: 50px 45px;
    .metadata {
        margin-bottom: 10px;
    }
}

.comment {
    & > div {
        padding-bottom: 30px;
        border-bottom: 1px solid $white-color;
        margin-bottom: 30px;
    }
    &-list > .comment:last-child > div {
        border-bottom-color: #ddd;
        margin-bottom: 50px;
    }
    &-list_children .comment > div {
        padding-left: 50px;
    }
    &-title {
        margin-bottom: 60px;
    }
    &-author {
        margin-bottom: 20px;
        img {
            border-radius: 100%;
        }
    }
    &-reply {
        color: $primary-color;
        font-size: 12px;
    }
}

@include media-breakpoint-up(sm) {
    .comment-area {
        padding: 70px 50px;
    }
    .comment {
        &-list_children .comment > div {
            padding-left: 100px;
        }
        &-author {
            float: left;
            margin-right: 30px;
        }
        &-reply {
            float: right;
            margin-top: -10px;
        }
    }
}

.reply-title {
    margin-bottom: 35px;
}

.comment-form {
    .row {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }
    [class*="col"] {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
