.nav-tabs {
    border-bottom: 0;
}
.nav-tabs > li > a {
    border-radius: 0;
    margin-right: 0;
}
.nav > li > a:focus, .nav > li > a:hover {
    background: transparent;
}

.tab-dn1 {
    .nav-tabs > li > a {
        border: 1px solid #eee;
        border-right-color: transparent;
        background: #f7f7f7;
        font-size: 14px;
        padding: 20px 25px;
        color: #212121;
        position: relative;
        &:before {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: $primary-color;
            position: absolute;
            top: -1px;
            left: -1px;
        }
    }
    .nav-tabs > li:last-child > a {
        border-right: 1px solid #eee;
    }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
        color: #212121;
        background: #f7f7f7;
        border-color: #eee;
        &:before {
            content: '';
            display: block;
            width: calc(100% + 2px);
        }
    }

    .tab-content {
        border: 1px solid #eee;
        padding: 50px 35px 40px;
    }
}

.tab-dn2 {
    .nav-tabs > li > a {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        color: #111;
        padding: 6px 20px;
        border: 1px solid transparent;
        border-right: 1px solid #ddd;
    }
    .nav > li > a:focus, .nav > li > a:hover {
        color: $primary-color;
        border-color: transparent;
        border-right-color: #ddd;
    }
    .nav-tabs > li:first-child > a {
        padding-left: 0;
    }
    .nav-tabs > li:last-child > a {
        border-right: 0;
    }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
        background: transparent;
        color: $primary-color;
    }
    .tab-content {
        padding: 30px 0px;
    }
}

.tab-dn3 {
    .nav-tabs {
        display: inline-block;
        width: 100%;
        margin-bottom: 30px;
    }
    .nav-tabs > li {
        float: none;
    }
    .nav-tabs > li:first-child > a {
        border-top-color: #ddd;
    }
    .nav-tabs > li > a {
        font-size: 12px;
        font-weight: 500;
        color: $black-color;
        border-bottom-color: #ddd;
        padding: 15px;
    }
    .nav > li > a:focus, .nav > li > a:hover,
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
        background: $secondary-color;
        color: $white-color;
    }
}

@include media-breakpoint-down(xs) {
    .nav-tabs > li {
        float: none;
    }
    .tab-dn2 .nav-tabs > li:first-child > a {
        padding-left: 20px;
    }
    .tab-dn2 .nav-tabs > li > a,
    .tab-dn2 .nav > li > a:focus, .nav > li > a:hover {
        border-left-color: #ddd;
        border-right-color: transparent;
    }

}

@include media-breakpoint-up(md) {
    .tab-dn3 {
        .nav-tabs, .tab-content {
            display: inline-block;
            vertical-align: top;
        }
        .nav-tabs {
            width: 270px;
            margin-right: 20px;
            margin-bottom: 0;
        }
        .tab-content {
            width: calc(100% - 300px);
        }
    }
}
