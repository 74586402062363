// CALL TO ACTION BANNERS
.banner {
    min-height: 400px;
    .row {
        min-height: 400px;
    }
    h1 {
        text-transform: inherit;
        margin-bottom: 25px;
    }
    p {
        margin-bottom: 30px;
    }
}

.banner.style-1 {
    background: url("../images/call-to-action_bg1.jpg") top left;
    background-size: cover;
}

.banner.style-2 {
    background: url("../images/call-to-action_bg2.jpg") top left;
    background-size: cover;
    h1 {
        color: $white-color;
    }
}

.banner.style-3 {
    background: url("../images/home/home1-section6_bg.jpg") top left;
    background-size: cover;
    padding: 150px 0px 150px;
    position: relative;
    overflow: hidden;
    h2 {
        color: $white-color;
        font-size: 60px;
        margin: 50px 0px 30px;
        text-transform: inherit;
    }
    p {
        color: $white-color;
    }
    @include media-breakpoint-down(sm) {
        padding: 100px 0px 100px;
        h2 {
            font-size: 40px;
        }
    }
    @include media-breakpoint-down(xs) {
        h2 {
            font-size: 36px;
        }
    }
}

.banner.style-4 {
    background: url("../images/call-to-action_bg3.jpg") bottom center no-repeat;
    min-height: 424px;
    padding: 60px 0px 40px;
}

.call-to-action {
    min-height: 440px;
    position: relative;
    &_content {
        @include center-y;
        width: 100%;
        padding: 0 15px;
    }
}
.call-to-action.style-01 {
    background: url("../images/home/home2-section9.jpg") top left;
    text-align: center;
    h2, p {
        color: #fff;
    }
    .call-to-action_content {
        padding: 0 30px;
        @include media-breakpoint-up(md) {
            padding: 0 70px;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 120px;
        }
    }
}
.call-to-action.style-02 {
    text-align: center;
    input {
        max-width: 500px;
        border-radius: 4px;
        text-align: center;
        &::-webkit-input-placeholder {
            color: $black-color;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            line-height: 25px;
        }

        &:-moz-placeholder {
            color: $black-color;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            line-height: 25px;
        }

        &::-moz-placeholder {
            color: $black-color;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            line-height: 25px;
        }

        &:-ms-input-placeholder {
            color: $black-color;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            line-height: 25px;
        }
        &.bttn {
            width: auto;
            display: block;
            margin: 0 auto;
        }
    }
}
