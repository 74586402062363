.slider {
    position: relative;
    overflow: hidden;
}

.slider-bg {
    width: 100%;
}

.svg-bottom-large {
    position: absolute;
    //bottom: 0px;
    top: 100%;
    margin-top: -294px;
    left: 50%;
    margin-left: -1280px;
}

// Slider 02
.slider-02 {
    min-height: 400px;
    background: url("../images/home/home2-section1.jpg") center center;
    background-size: cover;
    .slider-caption {
        @include center-y;
        left: 10%;
        text-align: center;
        margin-top: 30px;
        h2 {
            font-size: 32px;
        }
    }
}
@include media-breakpoint-up(md) {
    .slider-02 {
        min-height: 600px;
        .slider-caption {
            h2 {
                font-size: 50px;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .slider-02 {
        min-height: 1000px;
        .slider-caption {
            h2 {
                font-size: 80px;
            }
        }
    }
}

// Slider 06
.slider-06 {
    background: url("../images/home/home6-slider-bg.jpg") top center;
    background-size: cover;
    position: relative;
    min-height: 700px;

    .slider-caption {
        @include center-y;
        margin-left: 30px;
        max-width: 500px;
        img {
            width: 150px;
        }
    }
}
@include media-breakpoint-up(lg) {
    .slider-06 {
        min-height: 900px;
        .slider-caption {
            margin-left: 200px ;
            img {
                width: 300px;
            }
        }
    }
}

// Slider 07
.slider-07 {
    background: url("../images/home/home7-slider-bg.jpg") top center;
    background-size: cover;
    position: relative;
    min-height: 500px;
    overflow: hidden;
    .svg-bottom-small {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -1280px;
        z-index: 2;
    }
    .slider-caption {
        @include center-y;
        width: 100%;
        z-index: 3;
        padding: 20px;
        h2 {
            color: $white-color;
            font-size: 40px;
        }
        img {
            position: relative;
            width: calc(100%/3);
        }
    }
}

@include media-breakpoint-up(lg) {
    .slider-07 {
        min-height: 700px;
        overflow: visible;
        .slider-caption {
            margin-top: 20px;
            h2 {
                font-size: 50px;
            }
            img {
                margin-bottom: -100px;
                margin-left: -30px;
                margin-right: -30px;
                width: auto;
            }
        }
    }
}

// Slider 10
.slider-10 {
    background: url("../images/home/home10-section1.jpg") center center;
    background-size: cover;
    min-height: 600px;
    .slider-caption {
        @include center-y;
        width: 100%;
        text-align: center;
        h2 {
            font-weight: 600;
            font-size: 60px;
            margin-bottom: 20px;
        }
    }
}
@include media-breakpoint-up(md) {
    .slider-10 {
        .slider-caption {
            h2 {
                font-size: 70px;
            }
        }
    }
}

// Slider 11
.slider-11 {
    h2 {
        font-size: 40px;
        font-weight: 400;
        padding: 70px 0px 150px;
    }
}

@include media-breakpoint-up(md) {
    .slider-11 {
        h2 {
            font-size: 60px;
            padding: 100px 0px 200px;
        }
    }
}

// Slider 12
.slider-12 {
    background: url("../images/home/home12-slider-bg.jpg") top left;
    background-size: cover;
    padding-top: 140px;
    padding-bottom: 80px;
    min-height: 500px;
    h2 {
        font-size: 42px;
        margin-bottom: 20px;
    }
    p {
        color: #666;
        margin-bottom: 30px;
    }
}

@include media-breakpoint-up(sm) {
    .slider-12 {
        .slider-caption {
            @include center-y;
            width: 100%;
            padding-bottom: 30px;
        }
    }
}

@include media-breakpoint-up(md) {
    .slider-12 {
        h2 {
            font-size: 55px;
            margin-bottom: 30px;
        }
        p {
            margin-bottom: 40px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .slider-12 {
        h2 {
            font-size: 70px;
            margin-bottom: 30px;
        }
        p {
            margin-bottom: 40px;
        }
    }
}
