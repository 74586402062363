.bttn {
    display: inline-block;
    padding: 11px 30px;
    border: 1px solid $black-color;
    border-radius: 3px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $black-color;
    @extend %transit;
}


button.bttn {
    @extend %transit;
}

// Button sizes
.bttn-small {
    padding: 7px 25px;
}

.bttn-medium {
    padding: 11px 30px;
}

.bttn-large {
    padding: 15px 35px;
}

// Button styles
.bttn-border-black {
    border-color: $black-color;
    background: transparent;
    &:hover {
        background: $black-color;
        color: $white-color;
    }
}

.bttn-border-gray {
    border-color: #ddd;
    &:hover {
        background: $black-color;
        color: $white-color;
        border-color: $black-color;
    }
}

.bttn-border-white {
    border-color: #fff;
    color: #fff;
    &:hover {
        background: #fff;
        color: $secondary-color;
    }
}

.bttn-bg-primary-color {
    border-color: $primary-color;
    background: $primary-color;
    color: $white-color;
    &:hover {
        color: $primary-color;
        background: transparent;
    }
}

.bttn-bg-secondary-color {
    border-color: $secondary-color;
    background: $secondary-color;
    color: $white-color;
    &:hover {
        color: $secondary-color;
        background: transparent;
    }
}

.bttn-bg-white-color {
    border-color: #fff;
    background: #fff;
    color: $secondary-color;
    &:hover {
        background: transparent;
        color: #fff;
    }
}

.bttn-bg-black-color {
    border-color: $black-color;
    background: $black-color;
    color: $white-color;
    &:hover {
        color: $black-color;
        border-color: $black-color;
        background: transparent;
    }
}

// Link text
.link-text {
    color: $primary-color;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    position: relative;
    overflow: hidden;
    &-arrow {
        &:after {
            @extend %icon;
            content: '\f105';
            font-size: 14px;
            margin-left: 5px;
        }
    }
    span {
        position: relative;
        display: inline-block;
        @include transition(0.3s);
    }
    span:before {
        position: absolute;
        top: 100%;
        content: attr(data-hover);
        color: $primary-color;
        -webkit-transform: translate3d(0,0,0);
        -moz-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
}

.link-text:hover {
    span {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        transform: translateY(-100%);

    }
    &:before {
        color: red;
    }
}
