// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
    font-size: ($sizeValue * 16) * 1px;
    font-size: $sizeValue * 1rem;
}

// Clearfix
@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}

// Center block
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Center x
@mixin center-x {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

// Center y
@mixin center-y {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

// Center
@mixin is-translated($width: 100%) {
    width: $width;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

// Absolute center
@mixin absolute-center() {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

// Rounded
@mixin rounded($value) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    border-radius: $value;
}

// Font Awesome
@mixin font-awesome {
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

// Font pe-icon-7-stroke
@mixin font-pe-7s {
    font-family: 'Pe-icon-7-stroke';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Transition
@mixin transit {
    @include transition(all 0.4s $ease-in-out-cubic);
}

// Scale
@mixin scale($ratio:1.5) {
    -webkit-transform: scale($ratio);
    -moz-transform: scale($ratio);
    -o-transform: scale($ratio);
    -ms-transform: scale($ratio);
    transform: scale($ratio);
}

//Skew
@mixin skew($x,$y) {
    webkit-transform: skew($x, $y);
    -moz-transform: skew($x, $y);
    -ms-transform: skew($x, $y);
    -o-transform: skew($x, $y);
    transform: skew($x, $y);
}

//Rotate
@mixin rotate($rotate) {
    webkit-transform: rotate($rotate);
    -moz-transform: rotate($rotate);
    -ms-transform: rotate($rotate);
    -o-transform: rotate($rotate);
    transform: rotate($rotate);
}

// Arrows / Triangles
@mixin arrow($direction, $color, $border-width) {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;

    @if $direction == "left" {
        border-width: $border-width;
        border-right-color: $color;
    } @else if $direction == "right" {
        border-width: $border-width;
        border-left-color: $color;
    } @else if $direction == "up" {
        border-width: $border-width;
        border-bottom-color: $color;
    } @else if $direction == "down" {
        border-width: $border-width;
        border-top-color: $color;
    }

}
