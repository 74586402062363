.widget {
    margin-bottom: $space-medium;
    &-title {
        margin-bottom: $space-xsmall;
    }

}

// BLOG
.widget-search {
    margin-bottom: 20px;
    form {
        position: relative;
        &:after {
            content: '\f002';
            @extend %icon;
            font-size: 15px;
            color: #aaa;
            position: absolute;
            top: 15px;
            right: 20px;
        }
    }
}

.widget-newsletter {
    background: #eee;
    padding: 40px 15px 25px;
    text-align: center;
    input {
        text-align: center;
    }
}

.widget-photostream {
    .gallery {
        margin-left: -5px;
        margin-right: -5px;
        @extend %clearfix;
        &-image {
            float: left;
            margin-bottom: 10px;
            padding-left: 5px;
            padding-right: 5px;
            width: calc(100% / 3);
        }
        &-image.large {
            width: 100%;
        }
    }
}

.widget-recent-posts,
.widget-blog-categories {
    ul {
        border-top: 1px solid #eee;
        li {
            padding: 13px 5px 12px;
            border-bottom: 1px solid #eee;
        }
    }
}

.widget-recent-posts {
    a {
        color: $black-color;
        &:hover {
            color: $primary-color;
        }
    }
    .date {
        display: block;
        margin-top: 5px;
        font-size: 12px;
    }
}

// SHOP
.shop-categories {
    li {
        margin-bottom: 15px;
    }
}

.product-list-widget {
    li {
        @extend %clearfix;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #eee;
    }
    .product-img {
        width: 70px;
        float: left;
        margin-right: 15px;
    }
    .product-name {
        text-transform: capitalize;
    }
}
