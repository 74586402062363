// Forms
select, textarea, input {
    display: inline-block;
    font-size: 14px;
    padding: 12px 20px;
    margin-bottom: 10px;
    vertical-align: top;
    font-size: 14px;
    width: 100%;
    max-width: 100%;
    border: 1px solid #ddd;
    font-family: inherit;
    letter-spacing: inherit;
    outline-color: $primary-color;
    &:focus {
        border-color: $primary-color;
        outline: none;
        @extend %transit;
    }
}

input[type="radio"],
input[type="checkbox"] {
    width: auto;
}

.contact-form {
    [class*="col"] {
        padding: 0px 5px;
    }
}

.form-review {
    label {
        display: block;
        font-weight: 500;
        font-size: 12px;
        color: $black-color;
    }
    input, textarea {
        padding: 7px 20px;
    }
}

label {
    color: $black-color;
    font-weight: 500;
}
