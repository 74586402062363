.big-title {
    background: $secondary-color;
    padding: 60px 0px;
    h3, li, a {
        color: #fff;
        text-transform: capitalize;
    }
    h3 {
        font-weight: 300;
    }
    .breadcrumb {
        padding: 0;
        margin: 0;
    }
}

.breadcrumb {
    background: transparent;
    & > li + li:before {
        @extend %icon;
        content: '\f105';
        margin: 0px 5px 0px 2px;
    }

    > .active {
        color: #fff;
    }
}
