// Heading
.heading-1 {
    font-weight: 300;
    font-size: 48px;
}

.heading-2 {
    font-weight: 300;
    font-size: 36px;
}

.heading-3 {
    font-weight: 300;
    font-size: 24px;
}

.heading-4 {
    font-weight: 500;
    font-size: 18px;
}

.heading-5 {
    font-weight: 500;
    font-size: 14px;
}

.heading-6 {
    font-weight: 500;
    font-size: 12px;
}

// Blockquotes
.block-quote_style1 {
    color: $gray-color;
    border-left: 2px solid $primary-color;
    padding: 5px 15px;
    font-size: 14px;
}

.block-quote_style2 {
    color: $black-color;
    border-color: transparent;
    padding: 5px 15px 5px 90px;
    font-size: 14px;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 80px;
        height: 1px;
        background: #ddd;
        position: absolute;
        top: 50%;
        left: 0;
    }
}

.block-quote_style3 {
    color: $gray-color;
    background: $light-gray-color;
    border-left: 2px solid $black-color;
    padding: 15px 25px;
    font-size: 14px;
}

// Highlight text
.highlight-text-blue {
    background: $primary-color;
    color: #fff;
}

.highlight-text-gray {
    background: $light-gray-color;
}

.highlight-text-black {
    background: $black-color;
}

// Space
.no-margin {
    margin: 0;
}

.padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.padding-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.padding-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.padding-top {
    &-xsmall {
        padding-top: $space-xsmall;
    }
    &-small {
        padding-top: $space-small;
    }
    &-medium {
        padding-top: $space-medium;
    }
    &-large {
        padding-top: $space-large;
    }
    &-xlarge {
        padding-top: $space-xlarge;
    }
}

.padding-bottom {
    &-xsmall {
        padding-bottom: $space-xsmall;
    }
    &-small {
        padding-bottom: $space-small;
    }
    &-medium {
        padding-bottom: $space-medium;
    }
    &-large {
        padding-bottom: $space-large;
    }
    &-xlarge {
        padding-bottom: $space-xlarge;
    }
}

.margin-top {
    &-xsmall {
        margin-top: $space-xsmall;
    }
    &-small {
        margin-top: $space-small;
    }
    &-medium {
        margin-top: $space-medium;
    }
    &-large {
        margin-top: $space-large;
    }
    &-xlarge {
        margin-top: $space-xlarge;
    }
}

.margin-bottom {
    &-xsmall {
        margin-bottom: $space-xsmall;
    }
    &-small {
        margin-bottom: $space-small;
    }
    &-medium {
        margin-bottom: $space-medium;
    }
    &-large {
        margin-bottom: $space-large;
    }
    &-xlarge {
        margin-bottom: $space-xlarge;
    }
}
