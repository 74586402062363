#scrollUp {
    bottom: 20px;
    right: 20px;
    background: $primary-color;
    color: #fff;
    font-size: 45px;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    &:hover {
        -webkit-animation-name: hvr-wobble-vertical;
        animation-name: hvr-wobble-vertical;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }
}

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
    16.65% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    33.3% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    49.95% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
    }

    66.6% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
    }

    83.25% {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes hvr-wobble-vertical {
    16.65% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    33.3% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    49.95% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
    }

    66.6% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
    }

    83.25% {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
