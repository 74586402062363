.slick-slide {
    &:focus {
        outline: none;
    }
}
.slick-dots li button:before {
    //display: none;
    content: '';
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: transparent;
    border: 1px solid $gray-color;
    border-radius: 50%;
    opacity: 1;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    background: $gray-color;
}

.carousel-dn,
.carousel-dn2 {
    margin-bottom: 0;
    .slick-slide {
        margin: 0 15px;
    }
    .slick-list {
        margin: 0 -15px;
    }
    .slick-prev:before, .slick-next:before {
        @extend %icon-pe-7s;
        @extend %transit;
        font-size: 48px;
        opacity: 1;
    }
    .slick-prev:before{
        content: '\e686';
    }
    .slick-next:before {
        content: '\e684';
    }
}

.carousel-dn {
    overflow: hidden;
    .slick-prev, .slick-next {
        width: 50px;
        height: 80px;
        background: $black-color;
        z-index: 999;
        @extend %transit;
        &:hover {
            background: $primary-color;
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
}

.carousel-dn2 {
    .slick-prev, .slick-next {
        width: 50px;
        height: 50px;
        @extend %transit;
        &:hover {
            &:before {
                color: $primary-color;
            }
        }
    }
    .slick-prev:before, .slick-next:before {
        color: #000;
    }
    .slick-prev {
        left: -50px;
    }
    .slick-next {
        right: -50px;
    }
}
