// DIVIDERS
.divider-1, .divider-2 {
    display: table;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #eee;
}

.divider-2 {
    border-bottom: 2px solid #eee;
}
.divider-3 {
    border-bottom: 20px solid #efefef;
}

// Line Vertical
.line-vertical {
    width: 1px;
    height: 100px;
    background: #ddd;
    margin: 0 auto;
}

.line-vertical-2 {
    width: 2px;
    height: 100px;
    background: #111;
    margin: 0 auto;
}
