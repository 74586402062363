.site-footer {
    color: $gray-color;

    .widget {
        p {
            line-height: 1.6;
        }
    }
    &.bg-111 {
        .widget-title {
            color: $white-color;
        }
    }

    .social-menu .menu {
        li {
            margin-right: 10px;
        }
        a {
            color: #444;
            &:hover {
                color: $primary-color;
            }
        }
    }

    .form-subcribe {
        padding: 0px 15px;
        input[type="email"] {
            text-align: center;
            border-radius: 4px;
            margin-top: 20px;
            margin-bottom: 20px;
            @include media-breakpoint-up(sm) {
                max-width: 500px;
            }
            &::-webkit-input-placeholder {
                color: $black-color;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 12px;
                line-height: 25px;
            }

            &:-moz-placeholder {
                color: $black-color;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 12px;
                line-height: 25px;
            }

            &::-moz-placeholder {
                color: $black-color;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 12px;
                line-height: 25px;
            }

            &:-ms-input-placeholder {
                color: $black-color;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 12px;
                line-height: 25px;
            }
        }
        input.bttn {
            width: auto;
            display: block;
            margin: 0 auto;
        }
    }
}

.f-about-us-list {
    li {
        line-height: 2;
    }
    a {
        color: $gray-color;
        &:hover {
            color: $primary-color;
        }
    }
}

.f-talk-to-us-list {
    li {
        line-height: 2.6;
    }
    i {
        font-size: 18px;
        margin-right: 10px;
    }
}

.f-photo-list {
    margin-left: -5px;
    margin-right: -5px;
    li {
        float: left;
        padding: 5px;
    }
}

.bg-light-gray-dot {
    background: url("../images/footer-form_bg.jpg") top left;
}

// Copyright
.copyright {
    padding: 40px 0px;
}
