/* PAGE-PACKAGES-AND-PRICING */
.page-packages-and-pricing,
.page-pricing-tables {
    .page-header {
        height: 400px;
        position: relative;
        background: url("../images/packages-pricing_bg.jpg") top left;
        background-size: cover;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #000;
            opacity: 0.85;
        }
        &_content {
            @include center-y;
            width: 100%;
        }
        h2 {
            margin-top: 35px;
            margin-bottom: 25px;
            color: $white-color;
        }
    }
}

.packages-pricing-table {
    thead {
        background: #fcfcfc;
        * {
            text-align: center;
        }
    }
    .table > thead > tr > th {
        border-bottom: 1px solid #eee;
        vertical-align: middle;
        padding: 0;
        &:first-child {
            border-left: 1px solid #eee;
        }
    }
    .table > thead:first-child > tr:first-child > th {
        border-top: 1px solid #111;
        &.standard {
            border-top-color: #333;
        }
        &.professional {
            border-top-color: $secondary-color;
        }
        &.custom {
            border-top-color: $primary-color;
        }
    }
    &_title {
        background: #fff;
        color: #111;
        font-size: 12px;
        font-weight: 500;
        padding: 20px 30px;
        border: 1px solid #eee;
    }
    &_price {
        font-size: 32px;
        font-weight: 300;
        color: #fff;
        background: #111;
        padding:30px;
        &.standard {
            background: #333;
        }
        &.professional {
            background: $secondary-color;
        }
        &.custom {
            background: $primary-color;
        }
    }

    tbody > tr > td {
        text-align: center;
        padding: 20px 30px;
        &:first-child {
            text-align: left;
        }
    }
    .table > tbody > tr > td {
        border: 1px solid #eee;
    }
    .table-striped > tbody > tr:nth-of-type(odd) {
        background: #f8f8f8;
    }
    .table-striped > tbody > tr:nth-of-type(even) {
        background: #fcfcfc;
    }
    .fa-check {
        color: #59b200;
        font-size: 18px;
    }
    .fa-times {
        color: #d80000;
        font-size: 18px;
    }

    tfoot {
        text-align: center;
    }
    .table > tfoot > tr > td {
        padding: 30px;
        background: #fcfcfc;
        border: 1px solid #eee;
        &:first-child {
            background: transparent;
            border: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .packages-pricing-table {
        overflow-x: auto;
    }
}

/* PRICING-TABLES */
.pricing-tables {
    &.packages-pricing-table tbody > tr > td:first-child {
        text-align: center;
    }
    &.packages-pricing-table .table > tfoot > tr > td:first-child {
        background: #fcfcfc;
        border: 1px solid #eee;
    }
    th.professional h5 {
        background: #f5f5f5;
    }
    &.packages-pricing-table .table-striped > tbody > tr:nth-of-type(odd) {
        background: transparent;
    }
    tbody .pro, &.packages-pricing-table .table > tfoot > tr > td.pro {
        background: #f7f7f7;
    }
}
