body {
    font-family: $poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.05em;
    color: $gray-color;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    text-transform: uppercase;
    color: $black-color;
}

h1 {
    font-weight: 300;
    font-size: 48px;
    letter-spacing: 0.03em;
}

h2 {
    font-weight: 300;
    font-size: 36px;
    letter-spacing: 0.03em;
}

h3 {
    font-weight: 300;
    font-size: 24px;
    letter-spacing: 0.03em;
}

h4 {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
}

h5 {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.12em;
}

h6 {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.12em;
}

p {
    color: $gray-color;
    letter-spacing: 0.05em;
}

blockquote {
    letter-spacing: 0.05em;
}

a {
    color: $gray-color;
    &:hover, &:focus {
        color: $primary-color;
        text-decoration: none;
        outline: none;
    }
    @extend %transit;
}
