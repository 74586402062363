%clearfix {
    @include clearfix;
    &-sm {
        @include media-breakpoint-up(sm) {
            @include clearfix;
        }
    }
    &-md {
        @include media-breakpoint-up(md) {
            @include clearfix;
        }
    }
    &-lg {
        @include media-breakpoint-up(lg) {
            @include clearfix;
        }
    }
}

%icon {
    @include font-awesome;
    &-sm {
        @include media-breakpoint-up(sm) {
            @include font-awesome;
        }
    }
    &-md {
        @include media-breakpoint-up(md) {
            @include font-awesome;
        }
    }
    &-lg {
        @include media-breakpoint-up(lg) {
            @include font-awesome;
        }
    }
}

%icon-pe-7s {
    @include font-pe-7s;
    &-sm {
        @include media-breakpoint-up(sm) {
            @include font-pe-7s;
        }
    }
    &-md {
        @include media-breakpoint-up(md) {
            @include font-pe-7s;
        }
    }
    &-lg {
        @include media-breakpoint-up(lg) {
            @include font-pe-7s;
        }
    }
}

%transit {
    @include transit;
    &-sm {
        @include media-breakpoint-up(sm) {
            @include transit();
        }
    }
    &-md {
        @include media-breakpoint-up(md) {
            @include transit();
        }
    }
    &-lg {
        @include media-breakpoint-up(lg) {
            @include transit();
        }
    }
}

%absolute-center {
    @include absolute-center;
    &-sm {
        @include media-breakpoint-up(sm) {
            @include absolute-center();
        }
    }
    &-md {
        @include media-breakpoint-up(md) {
            @include absolute-center();
        }
    }
    &-lg {
        @include media-breakpoint-up(lg) {
            @include absolute-center();
        }
    }
}

%is-translate {
    @include is-translated;
    &-sm {
        @include media-breakpoint-up(sm) {
            @include is-translated();
        }
    }
    &-md {
        @include media-breakpoint-up(md) {
            @include is-translated();
        }
    }
    &-lg {
        @include media-breakpoint-up(lg) {
            @include is-translated();
        }
    }
}
