.gallery-lightbox {
    @extend %clearfix;
}
.gallery-item {
    margin-bottom: 30px;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    &:hover {
        .gallery-item_overlay {
            background: rgba(34, 34, 34, 0.8);
        }
        .gallery-item_overlay:before {
            margin-top: 0;
            opacity: 1;
            visibility: visible;
        }
    }
}

.gallery-item_overlay {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    background: rgba(0,0,0,0);
    @extend %transit;
    &:before {
        @extend %icon-pe-7s;
        content: '\e618';
        font-size: 40px;
        text-align: center;
        width: 100%;
        @include center-y;
        color: #fff;
        margin-top: -50px;
        opacity: 0;
        visibility: hidden;
        @extend %transit;
    }
}
