// PROGRESS
.progress-box {
    h2 {
        text-transform: inherit;
        margin-bottom: 25px;
    }
    p {
        margin-bottom: 60px;
    }
}

.progress {
    height: 4px;
    margin-bottom: 50px;
    background: transparent;
    box-shadow: none;
    overflow: inherit;
}

.progress-label {
    margin-bottom: 12px;
}

.progress-bar {
    position: relative;
    &.style-1 {
        background: $black-color;
    }
    &.style-2 {
        background: #333;
    }
    &.style-3 {
        background: $primary-color;
    }
    &.style-4 {
        background: $secondary-color;
    }
}

.progress_units {
    position: absolute;
    top: -25px;
    right: 0;
    color: $gray-color;
}

@include media-breakpoint-down(sm) {
    .progress-box {
        h2 {
            margin-top: 70px;
        }
    }
}


// PROGRESS STYLE 2
.progress-box.style-2 {
    margin-bottom: 100px;
}

@include media-breakpoint-up(md) {
    .progress-box.style-2 {
        margin-bottom: 0;
        img {
            position: relative;
            margin-top: -390px;
            z-index: -1;
        }
    }
}

.progress-item.reverse {
    .progress-label {
        text-align: right;
    }
    .progress-bar {
        float: right;
    }
    .progress_units {
        left: 0;
        right: auto;
    }
}

.progress-item.reverse-md {
    @include media-breakpoint-up(md) {
        .progress-label {
            text-align: right;
        }
        .progress-bar {
            float: right;
        }
        .progress_units {
            left: 0;
            right: auto;
        }
    }
}

.talk-box {
    font-weight: 300;
    padding: 20px;
    width: 300px;
    border-radius: 6px;
    position: relative;
    @include media-breakpoint-down(xs) {
        width: 100%;
    }
    &.style-1 {
        background: $primary-color;
        color: $white-color;
        &:before {
            content: '';
            @include arrow(right, $primary-color, 10px 0px 10px 10px);
            position: absolute;
            top: 50%;
            margin-top: -10px;
            left: 100%;
        }
    }
    &.style-2 {
        background: $light-gray-color;
        &:before {
            content: '';
            @include arrow(left, $light-gray-color, 10px 10px 10px 0px);
            position: absolute;
            top: 50%;
            margin-top: -10px;
            right: 100%;
        }
    }
}
