// COUNTDOWN CLOCK
.countdown {
    padding-top: 80px;
    padding-bottom: 80px;
}

.countdown-gray {
    background: $light-gray-color;
}

#ctimer, #ctimer2 {
    font-weight: 300;
    font-size: 36px;
    color: $primary-color;
    width: 100%;
    span {
        color: $black-color;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.25em;
        vertical-align: top;
        position: relative;
    }
}

.time {
    display: inline-block;
}

#ctimer span {
    margin-left: 10px;
    margin-right: 25px;
    top: 10px;
}

#ctimer2 span {
    vertical-align: top;
    position: relative;
    display: block;
}

#ctimer2 em {
    font-style: normal;
    margin: 0 50px;
    position: relative;
    top: -23px;
    color: $black-color;
}

@include media-breakpoint-down(xs) {
    #ctimer, #ctimer2 {
        padding: 15px;
        text-align: center;
    }
    .time {
        display: block;
    }
    em {
        display: none;
    }
}

@include media-breakpoint-up(md) {
    #ctimer, #ctimer2 {
        text-align: center;
    }
}
