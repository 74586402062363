// ALERTS
.alert {
    padding-left: 50px;
    position: relative;
    @include rounded(0px);
    &-icon {
        position: absolute;
        top: calc(50% - 11px);
        left: 20px;
    }
    .close {
        opacity: 1;
    }
    &-danger {
        background: $brand-danger;
        border-color: $brand-danger;
        color: #df6060;
        p, .close {
            color: #df6060;
        }
    }
    &-success {
        background: $brand-success;
        border-color: $brand-success;
        color: #58a839;
        p, .close {
            color: #58a839;
        }
    }
    &-info {
        background: $brand-info;
        border-color: $brand-info;
        color: #3d6ebf;
        p, .close {
            color: #3d6ebf;
        }
    }
    &-warning {
        background: $brand-warning;
        border-color: $brand-warning;
        color: #eb9b0a;
        p, .close {
            color: #eb9b0a;
        }
    }
}
