// Slideout css
body {
    width: 100%;
    height: 100%;
}

#menu-slideout {
    display: none;
}

.slideout-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.slideout-menu {
    left: auto;
}

.slideout-panel {
    position:relative;
    z-index: 1;
    background: #fff;
    will-change: transform;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open #menu-slideout {
    display: block;
}

.slideout-open #main {
    &:before {
        content: '';
        background-color: #000;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index:99999;
    }
}

// Mobile menu
.mobile-menu {
    > ul {
        li {
            display: block;
            position: relative;
            .sub-menu-toggle {
                font-size: 14px;
                line-height: 2.5em;
                position: absolute;
                top: 0px;
                right: 0px;
                @extend %transit;
            }
            a {
                display: block;
                color: $white-color;
                text-transform: uppercase;
                line-height: 2.5em;
            }
            &:last-child {
                border: none;
            }
            li {
                margin-left: 20px;
            }
            ul {
                display: none;
            }
        }
    }

    .expand > .sub-menu-toggle {
        @include rotate(-180deg);
    }
}

.slideout-menu {
    padding: 50px 30px;
    background: $black-color;
}

.mobile-section {
    margin-bottom: 50px;
    &_title {
        color: $white-color;
        margin-bottom: 20px;
    }
    a {
        line-height: 2em;
    }
}
.slideout-menu .social-menu .menu li a {
    color: $white-color;
    &:before {
        font-size: 18px;
        margin-right: 16px;
    }
}
