/* PORTFOLIO BUTTON FILTER */
.folio-main-filter,
.folio-carousel-filter {
    text-align: center;
    li {
        padding: 0px 15px;
    }
    a {
        color: $black-color;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
    }
}

/* FOLIO-CAROUSEL */
.carousel-main,
.carousel-main-4col {
    margin-bottom: 0;
    img {
        width: 100%;
    }

    // Prev, next button
    .slick-prev:before, .slick-next:before {
        @extend %icon-pe-7s;
        @extend %transit;
        font-size: 48px;
        opacity: 1;
    }
    .slick-prev:before{
        content: '\e686';
    }
    .slick-next:before {
        content: '\e684';
    }

    .slick-prev, .slick-next {
        width: 50px;
        height: 80px;
        background: $black-color;
        z-index: 999;
        @extend %transit;
        &:hover {
            background: $primary-color;
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
}
/* CAROUSEL-ITEM_CONTENT */
.carousel-main-item {
    position: relative;
    &:hover {
        .carousel-main-item_content {
            opacity: 1;
            visibility: visible;
        }
    }
}
.carousel-main-item_content {
    opacity: 0;
    visibility: hidden;
    background: #fff;
    text-align: center;
    @extend %transit;
    width: 250px;
    @include media-breakpoint-up(lg) {
        width: 46.8%;
        min-width: 300px;
        padding: 70px 20px 60px;
    }
    padding: 50px 20px 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    h3 {
        margin-top: 25px;
        margin-bottom: 20px;
        a {
            color: $black-color;
        }
    }
}

/* FOLIO-MAIN-GRID */
.folio-main-grid {
    .folio-main-item {
        width: 100%;
        padding: 0 15px;
        margin-bottom: 30px;
        @include media-breakpoint-up(sm) {
            width: 50%;
        }
        @include media-breakpoint-up(md) {
            width: 25%;
        }
        img {
            width: 100%;
        }
    }
    &.folio-main-2col {
        .folio-main-item {
            @include media-breakpoint-up(sm) {
                width: 50%;
            }
        }
    }
    &.folio-main-4col {
        .folio-main-item {
            @include media-breakpoint-up(sm) {
                width: 50%;
            }
            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
    }
    &.folio-main-4col-3inrow {
        .folio-main-item_sizer.folio-main-item {
            margin-bottom: 0;
        }
        .folio-main-item_sizer,
        .folio-main-item {
            @include media-breakpoint-up(sm) {
                width: 50%;
            }
            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
        .folio-main-item_width2 {
            @include media-breakpoint-up(sm) {
                width: 100%;
            }
            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }
    &.folio-main-3col,
    &.folio-main-3col-nopadding {
        .folio-main-item {
            @include media-breakpoint-up(sm) {
                width: 50%;
            }
            @include media-breakpoint-up(md) {
                width: calc(100%/3);
            }
        }
    }
    &.folio-main-3col-nopadding {
        .folio-main-item {
            padding: 0;
            margin-bottom: 0px;
        }
    }
    &.folio-main-5col {
        .folio-main-item_sizer.folio-main-item {
            margin-bottom: 0;
        }
        .folio-main-item_sizer,
        .folio-main-item {
            @include media-breakpoint-up(sm) {
                width: 25%;
            }
            @include media-breakpoint-up(md) {
                width: 20%;
            }
        }
        .folio-main-item_width2 {
            @include media-breakpoint-up(sm) {
                width: 50%;
            }
            @include media-breakpoint-up(md) {
                width: 40%;
            }
        }
    }
}

.carousel-main-4col {
    padding-left: 15px;
    padding-right: 15px;
    .carousel-main-item {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* PORTFOLIO ITEM */
.carousel-main-item,
.folio-main-item {
    .folio-wrapper {
        position: relative;
    }
    &:hover {
        .folio-overlay,
        .folio-overlay-inner,
        .folio-overlay-zoom {
            opacity: 1;
            visibility: visible;
        }
        .folio-overlay-inner {
            top: 50px;
        }
        .folio-overlay-zoom {
            @include scale(1);
        }
    }
}

.folio-overlay,
.folio-overlay-inner,
.folio-overlay-zoom {
    opacity: 0;
    visibility: hidden;
}

.folio-overlay {
    background: rgba(0,0,0,0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @extend %transit;
    h5 {
        color: #fff;
    }
    p {
        margin-bottom: 5px;
    }
    &-inner {
        position: absolute;
        top: 0px;
        left: 50px;
        @extend %transit;
    }
    &-zoom {
        position: absolute;
        bottom: 50px;
        left: 50px;
        font-size: 30px;
        @extend %transit;
        @include scale(0);
    }
}

/* PORTFOLIO DETAIL */
.page-portfolio-detail {
    .page-header {
        background: url("../images/portfolio/portfolio-detail_bg.jpg") top left;
        background-size: cover;
        @include media-breakpoint-up(lg) {
            height: 800px;
            position: relative;
            .page-header_content {
                @include center-y;
                width: 100%;
            }
        }
    }
    .page-header_content-inner {
        background: #fff;
        margin: 0 auto;
        padding: 75px 20px;
        @include media-breakpoint-up(md) {
            width: 600px;
            margin: 100px auto;
        }
    }
}

.portfolio-metadata {
    & > div {
        margin-bottom: 20px;
    }
    h6 {
        display: inline-block;
        margin-right: 8px;
    }
    .metadata {
        display: inline-block;
        span {
            margin-right: 10px;
        }
    }
}

.folio-item {
    position: relative;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    &.col-3 {
        width: calc(100%/3);
        @include media-breakpoint-down(xs) {
            width: calc(100%/2);
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        background: rgba(0,0,0,0.9);
        opacity: 0;
        visibility: hidden;
        @extend %transit;
    }

    svg {
        @include scale(0);
        @extend %transit;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        opacity: 0;
        visibility: hidden;

    }
    &:hover {
        &:before {
            opacity: 1;
            visibility: visible;
        }
        svg {
            @include scale(1);
            opacity: 1;
            visibility: visible;
        }
    }
}

