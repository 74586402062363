// Accordion
.accordion {
    .panel {
        box-shadow: none;
    }
    .panel-title {
        a {
            display: block;
            color: $white-color;
            background: $secondary-color;
            border: 1px solid $secondary-color;
            font-size: 14px;
            padding: 15px 50px 13px;
            position: relative;
            &:before, &:after {
                @extend %icon-pe-7s;
                @extend %transit;
                font-size: 24px;
                position: absolute;
                top: calc(50% - 12px);
            }
            &:before {
                content: '\e66d';
                left: 20px;
            }
            &:after {
                content: '\e684';
                right: 20px;
            }
            &.collapsed {
                color: $black-color;
                background: $light-gray-color;
                border-color: #eee;
                &:before {
                    color: $primary-color;
                }
                &:after {
                    @include rotate(90deg);
                }
            }
        }
    }
    .panel-body {
        padding: 35px 20px;
        color: $gray-color;
    }
}
