// Typography
.page-elements {
    &_typography {
        .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6, blockquote {
            margin-bottom: $space-medium;
        }
        p {
            color: $gray-color;
        }
    }
    &_buttons {
        h5 {
            margin-bottom: 40px;
        }
        .bttn {
            margin-bottom: 20px;
            margin-right: 20px;
        }
        .link-text {
            margin-bottom: 30px;
        }
    }
}

// Page header
.page-header {
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    border-bottom: none;
}

// Section-header
.section-header {
    text-align: center;
    h2 {
        text-transform: inherit;
        margin-top: 40px;
    }
}
