/* ABOUT 01 */
.about-1 {
    .page-header {
        height: 700px;
        background: url("../images/about-big-bg-1.jpg") top left;
        background-size: cover;
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #111;
            opacity: 0.9;
        }
        &_content {
            @include center-y;
            width: 100%;
        }
        h2 {
            margin-top: 50px;
            margin-bottom: 30px;
            color: $white-color;
            span {
                color: $primary-color;
            }
        }
        p {
            color: $white-color;
            padding: 0px 20px;
            @include media-breakpoint-up(sm) {
                width: 65%;
                margin: auto;
            }
        }
    }
}

.about-banner {
    background: url("../images/about/banner-bg.jpg") top left;
    background-size: cover;
    min-height: 600px;
    max-width: 700px;
    position: relative;
    z-index: 999;
    &_wrapper {
        @include center-y;
        padding: 0px 30px;
    }
    h2 {
        color: $white-color;
        font-size: 32px;
        margin-bottom: 35px;
    }
    p {
        color: $white-color;
        margin-bottom: 50px;
    }
    .bttn {
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

@include media-breakpoint-up(sm) {
    .about-banner {
        &_wrapper {
            padding: 0px 70px;
        }
        h2 {
            font-size: 36px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .about-banner {
        margin-top: -100px;
        width: 700px;
        height: 600px;
        float: right;
        &_wrapper {
            padding: 0px 100px;
        }
    }
}

.choose-us {
    position: relative;
    overflow: hidden;
    margin-bottom: 100px;
    .section-header {
        position: relative;
        z-index: 999;
    }
    .icon-boxes {
        z-index: 999;
        background: rgba(255, 255, 255, 0.5);
    }
    &_img {
        position: absolute;
        top: 0px;
        right: -200px;
        @include media-breakpoint-up(lg) {
            top: -80px;
            right: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .choose-us {
        overflow: visible;
    }
}

/* ABOUT 02 */
.about-2, {
    .page-header {
        padding: 100px 0px 150px;
        background: url("../images/about/page-header_ab2_bg.png") top left;
        position: relative;
        z-index: 1;
    }
    .progress-box {
        margin-top: -100px;
    }
}

.page-header_about-text {
    h2 {
        font-size: 48px;
        text-transform: inherit;
        margin-bottom: 25px;
        span {
            color: $primary-color;
        }
    }
    h4, p {
        margin-bottom: 25px;
    }
}

/* OUR TEAM */
.our-team_item {
    position: relative;
    margin-bottom: 30px;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        opacity: 0;
        @extend %transit;
    }
    &:hover {
        &::before {
            opacity: 0.9;
        }
        .our-team_item-content {
            opacity: 1;
        }
    }
    &-content {
        text-align: center;
        padding: 15px;
        opacity: 0;
        @extend %transit;
        @include center-y;
        h5 {
            color: $primary-color;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 30px;
        }
        .social-link a {
            color: $white-color;
            border: 1px solid #777;
            border-radius: 4px;
            display: inline-block;
            width: 45px;
            height: 45px;
            line-height: 45px;
            &:hover {
                background: $primary-color;
                border-color: $primary-color;
            }
        }
    }
}

.our-team_item.style-02 {
    .our-team_item-content {
        top: auto;
        bottom: 0;
        transform: none;
        padding: 30px;
        text-align: left;
        h4 {
            color: #fff;
            margin-bottom: 5px;
        }
        p {
            color: #fff;
            margin-bottom: 0;
        }
    }
}

/* ABOUT 03 */
.about-3 {
    .page-header_about-text {
        @include media-breakpoint-down(sm) {
            padding: 50px 30px;
        }
    }
}


@include media-breakpoint-up(lg) {
    .about-3 {
        .page-header_about-text {
            padding: 0px 45px;
        }
    }
}

/* ABOUT 04 */
.about-4 {
    .page-header {
        background: url("../images/about-big-bg-2.jpg") top left;
        background-size: cover;
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #000;
            opacity: 0.8;
        }
        &_content {
            padding-top: 150px;
            padding-bottom: 150px;
            h2 {
                color: #fff;
                font-size: 50px;
                max-width: 794px;
                margin: 0 auto;
                line-height: 1em;
            }
        }
    }
}

.banner-ab4 {
    background: url("../images/about/banner-ab4_bg.jpg") top left;
    background-size: cover;
    position: relative;
    text-align: center;
    min-height: 400px;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $secondary-color;
        opacity: 0.8;
    }
    h2 {
        color: $white-color;
        @include center-y;
        width: 100%;
        padding: 15px;
    }
}

.do-you-want {
    background: $primary-color;
    padding: 50px 0px 40px;
    h3 {
        text-transform: inherit;
        color: $white-color;
        margin-bottom: 10px;
    }
    .bttn {
        margin: 0px 0px 10px 5px;
    }
}

@include media-breakpoint-up(lg) {
    .about-4 {
        .page-header_content {
            padding-top: 300px;
            padding-bottom: 300px;
            h2 {
                font-size: 80px;
            }
        }
    }
}
