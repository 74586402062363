/*------------------------------------------------------------------
Theme Name: Dione
Version: 1.0
Author:
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]

## 01. GLOBAL STYLES
   01-01. Normalize
   01-02. Typography
   01-03. Custom class

## 02. COMBO CSS

## 03. NAVIGATION
   03-01. Menus
   03-02. Mobile menu
   03-03. Social menu
   03-04. Breadcrumb

## 04. LAYOUT
   04-01. Header
   04-02. Footer
   04-03. Widget, sidebar
   04-04. Mini cart

## 05. COMPONENTS
   05-01. Accordion
   05-02. Alerts
   05-03. Buttons
   05-04. Call to action
   05-05. Carousels
   05-06. Charts
   05-07. Collapse
   05-08. Countdown Clock
   05-09. Counters
   05-10. Dividers
   05-11. Drop caps
   05-12. Forms
   05-13. Gallery
   05-14. Icon Boxes
   05-15. Lists
   05-16. Pagination
   05-17. Scrollup
   05-18. Slider
   05-19. Table
   05-20. Tabs
   05-21. Testimonials
   05-22. Text Block
   05-23. Title Background
   05-24. Typography

## 06. CONTENT
   06-01. About
   06-02. Blog
   06-03. Contact
   06-04. Elements
   06-05. Home
   06-06. Our Services
   06-07. Our Team
   06-08. Packages Pricing
   06-09. Portfolio
   06-10. Shop
   06-11. Custom Class
-------------------------------------------------------------------*/
/*==============================================================
	01. GLOBAL STYLES
==============================================================*/
/*---------------------------
	01-01. Normalize
---------------------------*/
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

/*---------------------------
	01-02. Typography
---------------------------*/
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.05em;
  color: #999;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  text-transform: uppercase;
  color: #111;
}

h1 {
  font-weight: 300;
  font-size: 48px;
  letter-spacing: 0.03em;
}

h2 {
  font-weight: 300;
  font-size: 36px;
  letter-spacing: 0.03em;
}

h3 {
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 0.03em;
}

h4 {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
}

h5 {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.12em;
}

h6 {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.12em;
}

p {
  color: #999;
  letter-spacing: 0.05em;
}

blockquote {
  letter-spacing: 0.05em;
}

a {
  color: #999;
}

a:hover, a:focus {
  color: #00aeef;
  text-decoration: none;
  outline: none;
}

/*==============================================================
	02. COMBO CSS
==============================================================*/
.widget-photostream .gallery::after, .product-list-widget li::after, .gallery-lightbox::after, .blog-entry::after, .page-content::after, .home-04 .intro-item::after, .home-05 .our-works::after, .our-team_story::after {
  clear: both;
  content: "";
  display: table;
}

.social-menu .menu li a:before, .breadcrumb > li + li:before, .widget-search form:after, .link-text-arrow:after, .star-rating:before, .star-rating span:before {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

#primary-menu .sub-menu .has-child:after, .top-search form:before, .accordion .panel-title a:before, .accordion .panel-title a:after, .carousel-dn .slick-prev:before, .carousel-dn .slick-next:before,
.carousel-dn2 .slick-prev:before,
.carousel-dn2 .slick-next:before, .gallery-item_overlay:before, .dione-list.type1 li:before,
.dione-list.type2 li:before,
.dione-list.type3 li:before, .post-navigation .nav-previous a:before, .post-navigation .nav-next a:before, .carousel-main .slick-prev:before, .carousel-main .slick-next:before,
.carousel-main-4col .slick-prev:before,
.carousel-main-4col .slick-next:before {
  font-family: 'Pe-icon-7-stroke';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, #primary-menu .menu > ul > li > a:before, #primary-menu .menu > ul > li > a:after, #primary-menu .sub-menu, #primary-menu .sub-menu .has-child:after, .mobile-menu > ul li .sub-menu-toggle, .mini-cart .dropdown-menu, .accordion .panel-title a:before, .accordion .panel-title a:after, .bttn, button.bttn, .carousel-dn .slick-prev:before, .carousel-dn .slick-next:before,
.carousel-dn2 .slick-prev:before,
.carousel-dn2 .slick-next:before, .carousel-dn .slick-prev, .carousel-dn .slick-next, .carousel-dn2 .slick-prev, .carousel-dn2 .slick-next, select:focus, textarea:focus, input:focus, .gallery-item_overlay, .gallery-item_overlay:before, .our-team_item:before, .our-team_item-content, .home-05 .our-works_item:before, .home-05 .our-works_overlay, .home-10 .shop-banner_link:before, .carousel-main .slick-prev:before, .carousel-main .slick-next:before,
.carousel-main-4col .slick-prev:before,
.carousel-main-4col .slick-next:before, .carousel-main .slick-prev, .carousel-main .slick-next,
.carousel-main-4col .slick-prev,
.carousel-main-4col .slick-next, .carousel-main-item_content, .folio-overlay, .folio-overlay-inner, .folio-overlay-zoom, .folio-item:before, .folio-item svg, .product-link:before, .product-button, .shop-banner img, .shop-banner h3 {
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/*==============================================================
	03. NAVIGATION
==============================================================*/
/*---------------------------
	03-01. Menus
---------------------------*/
.menu,
.menu li {
  position: relative;
}

.menu > ul > li {
  display: inline-block;
}

.menu > ul > li > a {
  display: inline-block;
}

.menu li:hover > ul {
  opacity: 1;
  visibility: visible;
}

.sub-menu {
  width: 200px;
  position: absolute;
  top: 100%;
  left: 0px;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  background: #fff;
  text-align: left;
}

.sub-menu li > .sub-menu {
  top: 0;
  left: 100%;
}

.sub-menu li {
  display: block;
}

.sub-menu li a {
  display: block;
}

li.mega-menu {
  position: static;
}

li.mega-menu .sub-menu {
  width: 100%;
}

#primary-menu .menu > ul > li {
  padding: 15px 12px;
  margin: 0 13px;
  color: #111;
}

#primary-menu .menu > ul > li > a {
  color: #111;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  position: relative;
}

#primary-menu .menu > ul > li > a:before, #primary-menu .menu > ul > li > a:after {
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

#primary-menu .menu > ul > li > a:before {
  content: '[';
  left: -15px;
}

#primary-menu .menu > ul > li > a:after {
  content: ']';
  right: -15px;
}

#primary-menu .menu > ul > li:hover > a:before, #primary-menu .menu > ul > li:hover > a:after, #primary-menu .menu > ul > li.active > a:before, #primary-menu .menu > ul > li.active > a:after {
  opacity: 1;
  visibility: visible;
}

#primary-menu .menu > ul > li:hover > a:before, #primary-menu .menu > ul > li.active > a:before {
  left: -10px;
}

#primary-menu .menu > ul > li:hover > a:after, #primary-menu .menu > ul > li.active > a:after {
  right: -10px;
}

#primary-menu .menu > ul > li:hover > .sub-menu {
  margin-top: 0;
}

#primary-menu .menu > ul > li > .sub-menu {
  margin-top: 30px;
}

#primary-menu .menu > ul > li.no-before-after:before, #primary-menu .menu > ul > li.no-before-after:after {
  display: none;
}

#primary-menu .sub-menu {
  padding: 20px 0px;
  border-top: 2px solid #111;
}

#primary-menu .sub-menu li a {
  padding: 10px 20px;
  text-transform: capitalize;
  color: #111;
}

#primary-menu .sub-menu li a:hover {
  color: #00aeef;
}

#primary-menu .sub-menu > li > .sub-menu {
  margin-left: 30px;
  margin-top: 0;
  top: -22px;
}

#primary-menu .sub-menu > li:hover > .sub-menu {
  margin-left: 0;
}

#primary-menu .sub-menu .has-child:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  content: '\e684';
  position: absolute;
  right: 10px;
  font-size: 20px;
}

#primary-menu .sub-menu .has-child:hover:after {
  color: #00aeef;
}

/*---------------------------
	03-02. Mobile menu
---------------------------*/
body {
  width: 100%;
  height: 100%;
}

#menu-slideout {
  display: none;
}

.slideout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 256px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.slideout-menu {
  left: auto;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  background: #fff;
  will-change: transform;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open #menu-slideout {
  display: block;
}

.slideout-open #main:before {
  content: '';
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.mobile-menu > ul li {
  display: block;
  position: relative;
}

.mobile-menu > ul li .sub-menu-toggle {
  font-size: 14px;
  line-height: 2.5em;
  position: absolute;
  top: 0px;
  right: 0px;
}

.mobile-menu > ul li a {
  display: block;
  color: #fff;
  text-transform: uppercase;
  line-height: 2.5em;
}

.mobile-menu > ul li:last-child {
  border: none;
}

.mobile-menu > ul li li {
  margin-left: 20px;
}

.mobile-menu > ul li ul {
  display: none;
}

.mobile-menu .expand > .sub-menu-toggle {
  webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.slideout-menu {
  padding: 50px 30px;
  background: #111;
}

.mobile-section {
  margin-bottom: 50px;
}

.mobile-section_title {
  color: #fff;
  margin-bottom: 20px;
}

.mobile-section a {
  line-height: 2em;
}

.slideout-menu .social-menu .menu li a {
  color: #fff;
}

.slideout-menu .social-menu .menu li a:before {
  font-size: 18px;
  margin-right: 16px;
}

/*---------------------------
	03-03. Social menu
---------------------------*/
.social-menu .menu li {
  display: inline-block;
  width: auto !important;
}

.social-menu .menu li:before {
  display: none;
}

.social-menu .menu li a {
  font-size: 0;
  color: #111;
}

.social-menu .menu li a:hover {
  color: #00aeef;
}

.social-menu .menu li a:before {
  display: inline-block;
  font-size: 18px;
}

.social-menu .menu li a[href*="facebook"]:before {
  content: "\f09a";
}

.social-menu .menu li a[href*="twitter"]:before {
  content: "\f099";
}

.social-menu .menu li a[href*="instagram"]:before {
  content: "\f16d";
}

.social-menu .menu li a[href*="youtube"]:before {
  content: "\f16a";
}

.social-menu .menu li a[href*="plus.google.com"]:before {
  content: "\f0d5";
}

.social-menu .menu li a[href*="flickr"]:before {
  content: "\f16e";
}

.social-menu .menu li a[href*="dribbble"]:before {
  content: "\f17d";
}

.social-menu .menu li a[href*="foursquare"]:before {
  content: "\f180";
}

.social-menu .menu li a[href*="linkedin"]:before {
  content: "\f0e1";
}

.social-menu .menu li a[href*="tumblr"]:before {
  content: "\f173";
}

.social-menu .menu li a[href*="feed"]:before {
  content: "\f09e";
}

.social-menu .menu li a[href*="pinterest"]:before {
  content: "\f0d2";
}

/*---------------------------
	03-04. Breadcrumb
---------------------------*/
.big-title {
  background: #0076a3;
  padding: 60px 0px;
}

.big-title h3, .big-title li, .big-title a {
  color: #fff;
  text-transform: capitalize;
}

.big-title h3 {
  font-weight: 300;
}

.big-title .breadcrumb {
  padding: 0;
  margin: 0;
}

.breadcrumb {
  background: transparent;
}

.breadcrumb > li + li:before {
  content: '\f105';
  margin: 0px 5px 0px 2px;
}

.breadcrumb > .active {
  color: #fff;
}

/*==============================================================
	04. LAYOUT
==============================================================*/
/*---------------------------
	04-01. Header
---------------------------*/
.header-wrapper {
  position: relative;
}

.logo {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mini-cart {
  margin-top: 5px;
}

.search-icon {
  margin-left: 30px;
  margin-top: 5px;
}

.mobile-menu-btn {
  margin-left: 30px;
  margin-top: 5px;
}

/*---------------------------
	04-02. Footer
---------------------------*/
.site-footer {
  color: #999;
}

.site-footer .widget p {
  line-height: 1.6;
}

.site-footer.bg-111 .widget-title {
  color: #fff;
}

.site-footer .social-menu .menu li {
  margin-right: 10px;
}

.site-footer .social-menu .menu a {
  color: #444;
}

.site-footer .social-menu .menu a:hover {
  color: #00aeef;
}

.site-footer .form-subcribe {
  padding: 0px 15px;
}

.site-footer .form-subcribe input[type="email"] {
  text-align: center;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 48em) {
  .site-footer .form-subcribe input[type="email"] {
    max-width: 500px;
  }
}

.site-footer .form-subcribe input[type="email"]::-webkit-input-placeholder {
  color: #111;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
}

.site-footer .form-subcribe input[type="email"]:-moz-placeholder {
  color: #111;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
}

.site-footer .form-subcribe input[type="email"]::-moz-placeholder {
  color: #111;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
}

.site-footer .form-subcribe input[type="email"]:-ms-input-placeholder {
  color: #111;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
}

.site-footer .form-subcribe input.bttn {
  width: auto;
  display: block;
  margin: 0 auto;
}

.f-about-us-list li {
  line-height: 2;
}

.f-about-us-list a {
  color: #999;
}

.f-about-us-list a:hover {
  color: #00aeef;
}

.f-talk-to-us-list li {
  line-height: 2.6;
}

.f-talk-to-us-list i {
  font-size: 18px;
  margin-right: 10px;
}

.f-photo-list {
  margin-left: -5px;
  margin-right: -5px;
}

.f-photo-list li {
  float: left;
  padding: 5px;
}

.bg-light-gray-dot {
  background: url("../images/footer-form_bg.jpg") top left;
}

.copyright {
  padding: 40px 0px;
}

/*---------------------------
	04-03. Widget, sidebar
---------------------------*/
.widget {
  margin-bottom: 50px;
}

.widget-title {
  margin-bottom: 20px;
}

.widget-search {
  margin-bottom: 20px;
}

.widget-search form {
  position: relative;
}

.widget-search form:after {
  content: '\f002';
  font-size: 15px;
  color: #aaa;
  position: absolute;
  top: 15px;
  right: 20px;
}

.widget-newsletter {
  background: #eee;
  padding: 40px 15px 25px;
  text-align: center;
}

.widget-newsletter input {
  text-align: center;
}

.widget-photostream .gallery {
  margin-left: -5px;
  margin-right: -5px;
}

.widget-photostream .gallery-image {
  float: left;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  width: calc(100% / 3);
}

.widget-photostream .gallery-image.large {
  width: 100%;
}

.widget-recent-posts ul,
.widget-blog-categories ul {
  border-top: 1px solid #eee;
}

.widget-recent-posts ul li,
.widget-blog-categories ul li {
  padding: 13px 5px 12px;
  border-bottom: 1px solid #eee;
}

.widget-recent-posts a {
  color: #111;
}

.widget-recent-posts a:hover {
  color: #00aeef;
}

.widget-recent-posts .date {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}

.shop-categories li {
  margin-bottom: 15px;
}

.product-list-widget li {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.product-list-widget .product-img {
  width: 70px;
  float: left;
  margin-right: 15px;
}

.product-list-widget .product-name {
  text-transform: capitalize;
}

/*---------------------------
	04-04. Search
---------------------------*/
.search-icon {
  font-size: 22px;
  line-height: 1;
}

.search-icon a {
  color: #111;
}

.top-search {
  background: #111;
  overflow: hidden;
}

.top-search form {
  position: relative;
}

.top-search form:before {
  content: "\e618";
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -10px;
}

.top-search form input {
  font-size: 16px;
  width: 100%;
  border: 0;
  background: transparent;
  margin-bottom: 0;
  padding: 20px 0px 20px 30px;
}

.top-search .search-close {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -12px;
}

.top-search .search-close a {
  font-size: 24px;
  line-height: 1;
}

/*---------------------------
	04-05. Mini cart
---------------------------*/
.mini-cart {
  position: static;
}

.mini-cart_button {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 20px;
  color: #111;
  position: relative;
}

.mini-cart_button:after {
  content: attr(data-count);
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: -10px;
  right: -12px;
  min-width: 20px;
  min-height: 20px;
  line-height: 21px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #fff;
  background: #00aeef;
}

.mini-cart .dropdown-menu {
  z-index: 99999;
  display: block;
  opacity: 0;
  visibility: hidden;
  margin-top: 40px;
}

.mini-cart.open .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 10px;
}

.mini-cart .cart-list {
  right: 15px;
}

.mini-cart.white-style .mini-cart_button {
  color: #fff;
  border-color: #fff;
}

.mini-cart.white-style .cart-list {
  border-top: 0;
}

.cart-list {
  width: 300px;
  border-top: 2px solid #111;
  border-radius: 0;
  padding: 0;
}

.cart-list_item {
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.cart-list .product-img, .cart-list .product-content {
  display: table-cell;
  vertical-align: middle;
}

.cart-list .product-img img {
  width: 70px;
}

.cart-list .product-content {
  padding-left: 15px;
}

.cart-list .product-name {
  display: block;
  text-transform: capitalize;
}

.cart-list .total {
  color: #111;
  font-weight: 500;
}

.cart-list .cart-list-btns {
  text-align: center;
}

.cart-list .cart-btn {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid #111;
  border-radius: 4px;
  padding: 8px 20px;
}

.cart-list .cart-btn:hover {
  color: #fff;
  background: #111;
}

@media (max-width: 47.9em) {
  .cart-list {
    width: 290px;
  }
}

@media (min-width: 80em) {
  .mini-cart {
    position: relative;
  }
  .mini-cart .cart-list {
    right: 0;
  }
}

/*==============================================================
	05. COMPONENTS
==============================================================*/
/*---------------------------
	05-01. Accordion
---------------------------*/
.accordion .panel {
  box-shadow: none;
}

.accordion .panel-title a {
  display: block;
  color: #fff;
  background: #0076a3;
  border: 1px solid #0076a3;
  font-size: 14px;
  padding: 15px 50px 13px;
  position: relative;
}

.accordion .panel-title a:before, .accordion .panel-title a:after {
  font-size: 24px;
  position: absolute;
  top: calc(50% - 12px);
}

.accordion .panel-title a:before {
  content: '\e66d';
  left: 20px;
}

.accordion .panel-title a:after {
  content: '\e684';
  right: 20px;
}

.accordion .panel-title a.collapsed {
  color: #111;
  background: #f5f5f5;
  border-color: #eee;
}

.accordion .panel-title a.collapsed:before {
  color: #00aeef;
}

.accordion .panel-title a.collapsed:after {
  webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.accordion .panel-body {
  padding: 35px 20px;
  color: #999;
}

/*---------------------------
	05-02. Alerts
---------------------------*/
.alert {
  padding-left: 50px;
  position: relative;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.alert-icon {
  position: absolute;
  top: calc(50% - 11px);
  left: 20px;
}

.alert .close {
  opacity: 1;
}

.alert-danger {
  background: #ffecec;
  border-color: #ffecec;
  color: #df6060;
}

.alert-danger p, .alert-danger .close {
  color: #df6060;
}

.alert-success {
  background: #e0f2d9;
  border-color: #e0f2d9;
  color: #58a839;
}

.alert-success p, .alert-success .close {
  color: #58a839;
}

.alert-info {
  background: #ecf1f9;
  border-color: #ecf1f9;
  color: #3d6ebf;
}

.alert-info p, .alert-info .close {
  color: #3d6ebf;
}

.alert-warning {
  background: #fdf9df;
  border-color: #fdf9df;
  color: #eb9b0a;
}

.alert-warning p, .alert-warning .close {
  color: #eb9b0a;
}

/*---------------------------
	05-03. Buttons
---------------------------*/
.bttn {
  display: inline-block;
  padding: 11px 30px;
  border: 1px solid #111;
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #111;
}

.bttn-small {
  padding: 7px 25px;
}

.bttn-medium {
  padding: 11px 30px;
}

.bttn-large {
  padding: 15px 35px;
}

.bttn-border-black {
  border-color: #111;
  background: transparent;
}

.bttn-border-black:hover {
  background: #111;
  color: #fff;
}

.bttn-border-gray {
  border-color: #ddd;
}

.bttn-border-gray:hover {
  background: #111;
  color: #fff;
  border-color: #111;
}

.bttn-border-white {
  border-color: #fff;
  color: #fff;
}

.bttn-border-white:hover {
  background: #fff;
  color: #0076a3;
}

.bttn-bg-primary-color {
  border-color: #00aeef;
  background: #00aeef;
  color: #fff;
}

.bttn-bg-primary-color:hover {
  color: #00aeef;
  background: transparent;
}

.bttn-bg-secondary-color {
  border-color: #0076a3;
  background: #0076a3;
  color: #fff;
}

.bttn-bg-secondary-color:hover {
  color: #0076a3;
  background: transparent;
}

.bttn-bg-white-color {
  border-color: #fff;
  background: #fff;
  color: #0076a3;
}

.bttn-bg-white-color:hover {
  background: transparent;
  color: #fff;
}

.bttn-bg-black-color {
  border-color: #111;
  background: #111;
  color: #fff;
}

.bttn-bg-black-color:hover {
  color: #111;
  border-color: #111;
  background: transparent;
}

.link-text {
  color: #00aeef;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.link-text-arrow:after {
  content: '\f105';
  font-size: 14px;
  margin-left: 5px;
}

.link-text span {
  position: relative;
  display: inline-block;
  transition: 0.3s;
}

.link-text span:before {
  position: absolute;
  top: 100%;
  content: attr(data-hover);
  color: #00aeef;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.link-text:hover span {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
}

.link-text:hover:before {
  color: red;
}

/*---------------------------
	05-04. Call to action
---------------------------*/
.banner {
  min-height: 400px;
}

.banner .row {
  min-height: 400px;
}

.banner h1 {
  text-transform: inherit;
  margin-bottom: 25px;
}

.banner p {
  margin-bottom: 30px;
}

.banner.style-1 {
  background: url("../images/call-to-action_bg1.jpg") top left;
  background-size: cover;
}

.banner.style-2 {
  background: url("../images/call-to-action_bg2.jpg") top left;
  background-size: cover;
}

.banner.style-2 h1 {
  color: #fff;
}

.banner.style-3 {
  background: url("../images/home/home1-section6_bg.jpg") top left;
  background-size: cover;
  padding: 150px 0px 150px;
  position: relative;
  overflow: hidden;
}

.banner.style-3 h2 {
  color: #fff;
  font-size: 60px;
  margin: 50px 0px 30px;
  text-transform: inherit;
}

.banner.style-3 p {
  color: #fff;
}

@media (max-width: 63.9em) {
  .banner.style-3 {
    padding: 100px 0px 100px;
  }
  .banner.style-3 h2 {
    font-size: 40px;
  }
}

@media (max-width: 47.9em) {
  .banner.style-3 h2 {
    font-size: 36px;
  }
}

.banner.style-4 {
  background: url("../images/call-to-action_bg3.jpg") bottom center no-repeat;
  min-height: 424px;
  padding: 60px 0px 40px;
}

.call-to-action {
  min-height: 440px;
  position: relative;
}

.call-to-action_content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  padding: 0 15px;
}

.call-to-action.style-01 {
  background: url("../images/home/home2-section9.jpg") top left;
  text-align: center;
}

.call-to-action.style-01 h2, .call-to-action.style-01 p {
  color: #fff;
}

.call-to-action.style-01 .call-to-action_content {
  padding: 0 30px;
}

@media (min-width: 64em) {
  .call-to-action.style-01 .call-to-action_content {
    padding: 0 70px;
  }
}

@media (min-width: 80em) {
  .call-to-action.style-01 .call-to-action_content {
    padding: 0 120px;
  }
}

.call-to-action.style-02 {
  text-align: center;
}

.call-to-action.style-02 input {
  max-width: 500px;
  border-radius: 4px;
  text-align: center;
}

.call-to-action.style-02 input::-webkit-input-placeholder {
  color: #111;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
}

.call-to-action.style-02 input:-moz-placeholder {
  color: #111;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
}

.call-to-action.style-02 input::-moz-placeholder {
  color: #111;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
}

.call-to-action.style-02 input:-ms-input-placeholder {
  color: #111;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
}

.call-to-action.style-02 input.bttn {
  width: auto;
  display: block;
  margin: 0 auto;
}

/*---------------------------
	05-05. Carousels
---------------------------*/
.slick-slide:focus {
  outline: none;
}

.slick-dots li button:before {
  content: '';
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #999;
  border-radius: 50%;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  background: #999;
}

.carousel-dn,
.carousel-dn2 {
  margin-bottom: 0;
}

.carousel-dn .slick-slide,
.carousel-dn2 .slick-slide {
  margin: 0 15px;
}

.carousel-dn .slick-list,
.carousel-dn2 .slick-list {
  margin: 0 -15px;
}

.carousel-dn .slick-prev:before, .carousel-dn .slick-next:before,
.carousel-dn2 .slick-prev:before,
.carousel-dn2 .slick-next:before {
  font-size: 48px;
  opacity: 1;
}

.carousel-dn .slick-prev:before,
.carousel-dn2 .slick-prev:before {
  content: '\e686';
}

.carousel-dn .slick-next:before,
.carousel-dn2 .slick-next:before {
  content: '\e684';
}

.carousel-dn {
  overflow: hidden;
}

.carousel-dn .slick-prev, .carousel-dn .slick-next {
  width: 50px;
  height: 80px;
  background: #111;
  z-index: 999;
}

.carousel-dn .slick-prev:hover, .carousel-dn .slick-next:hover {
  background: #00aeef;
}

.carousel-dn .slick-prev {
  left: 0;
}

.carousel-dn .slick-next {
  right: 0;
}

.carousel-dn2 .slick-prev, .carousel-dn2 .slick-next {
  width: 50px;
  height: 50px;
}

.carousel-dn2 .slick-prev:hover:before, .carousel-dn2 .slick-next:hover:before {
  color: #00aeef;
}

.carousel-dn2 .slick-prev:before, .carousel-dn2 .slick-next:before {
  color: #000;
}

.carousel-dn2 .slick-prev {
  left: -50px;
}

.carousel-dn2 .slick-next {
  right: -50px;
}

/*---------------------------
	05-06. Charts
---------------------------*/
.progress-box h2 {
  text-transform: inherit;
  margin-bottom: 25px;
}

.progress-box p {
  margin-bottom: 60px;
}

.progress {
  height: 4px;
  margin-bottom: 50px;
  background: transparent;
  box-shadow: none;
  overflow: inherit;
}

.progress-label {
  margin-bottom: 12px;
}

.progress-bar {
  position: relative;
}

.progress-bar.style-1 {
  background: #111;
}

.progress-bar.style-2 {
  background: #333;
}

.progress-bar.style-3 {
  background: #00aeef;
}

.progress-bar.style-4 {
  background: #0076a3;
}

.progress_units {
  position: absolute;
  top: -25px;
  right: 0;
  color: #999;
}

@media (max-width: 63.9em) {
  .progress-box h2 {
    margin-top: 70px;
  }
}

.progress-box.style-2 {
  margin-bottom: 100px;
}

@media (min-width: 64em) {
  .progress-box.style-2 {
    margin-bottom: 0;
  }
  .progress-box.style-2 img {
    position: relative;
    margin-top: -390px;
    z-index: -1;
  }
}

.progress-item.reverse .progress-label {
  text-align: right;
}

.progress-item.reverse .progress-bar {
  float: right;
}

.progress-item.reverse .progress_units {
  left: 0;
  right: auto;
}

@media (min-width: 64em) {
  .progress-item.reverse-md .progress-label {
    text-align: right;
  }
  .progress-item.reverse-md .progress-bar {
    float: right;
  }
  .progress-item.reverse-md .progress_units {
    left: 0;
    right: auto;
  }
}

.talk-box {
  font-weight: 300;
  padding: 20px;
  width: 300px;
  border-radius: 6px;
  position: relative;
}

@media (max-width: 47.9em) {
  .talk-box {
    width: 100%;
  }
}

.talk-box.style-1 {
  background: #00aeef;
  color: #fff;
}

.talk-box.style-1:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 10px 0px 10px 10px;
  border-left-color: #00aeef;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 100%;
}

.talk-box.style-2 {
  background: #f5f5f5;
}

.talk-box.style-2:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 10px 10px 10px 0px;
  border-right-color: #f5f5f5;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 100%;
}

/*---------------------------
	05-07. Collapse
---------------------------*/
/*---------------------------
	05-08. Countdown Clock
---------------------------*/
.countdown {
  padding-top: 80px;
  padding-bottom: 80px;
}

.countdown-gray {
  background: #f5f5f5;
}

#ctimer, #ctimer2 {
  font-weight: 300;
  font-size: 36px;
  color: #00aeef;
  width: 100%;
}

#ctimer span, #ctimer2 span {
  color: #111;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25em;
  vertical-align: top;
  position: relative;
}

.time {
  display: inline-block;
}

#ctimer span {
  margin-left: 10px;
  margin-right: 25px;
  top: 10px;
}

#ctimer2 span {
  vertical-align: top;
  position: relative;
  display: block;
}

#ctimer2 em {
  font-style: normal;
  margin: 0 50px;
  position: relative;
  top: -23px;
  color: #111;
}

@media (max-width: 47.9em) {
  #ctimer, #ctimer2 {
    padding: 15px;
    text-align: center;
  }
  .time {
    display: block;
  }
  em {
    display: none;
  }
}

@media (min-width: 64em) {
  #ctimer, #ctimer2 {
    text-align: center;
  }
}

/*---------------------------
	05-09. Counters
---------------------------*/
.counter-box {
  padding: 90px 0px;
}

.counter-box h2 {
  margin-bottom: 10px;
}

.counter-box.style-1 {
  background: url("../images/counter_bg1.jpg") top left;
}

.counter-box.style-1 h2 {
  color: #00aeef;
}

.counter-box.style-2 {
  background: #0076a3 url("../images/counter_bg2.jpg") top left;
}

.counter-box.style-2 h2, .counter-box.style-2 h5 {
  color: #fff;
}

@media (max-width: 47.9em) {
  .counter-box {
    padding-bottom: 55px;
  }
  .counter-item {
    margin-bottom: 35px;
  }
}

/*---------------------------
	05-10. Dividers
---------------------------*/
.divider-1, .divider-2 {
  display: table;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #eee;
}

.divider-2 {
  border-bottom: 2px solid #eee;
}

.divider-3 {
  border-bottom: 20px solid #efefef;
}

.line-vertical {
  width: 1px;
  height: 100px;
  background: #ddd;
  margin: 0 auto;
}

.line-vertical-2 {
  width: 2px;
  height: 100px;
  background: #111;
  margin: 0 auto;
}

/*---------------------------
	05-11. Drop caps
---------------------------*/
.drop-caps:first-letter, .drop-caps.style-01:first-letter {
  font-size: 32px;
  line-height: 1;
  color: #222;
  padding: 12px 21px;
  display: block;
  float: left;
  margin: -20px 20px 0px 0;
}

.drop-caps.style-01:first-letter {
  border: 1px solid #DDD;
}

.drop-caps.style-02:first-letter {
  color: #fff;
  background-color: #0076A3;
}

.drop-caps.style-03:first-letter {
  color: #fff;
  background-color: #00AEEF;
}

.drop-caps.style-04:first-letter {
  color: #222;
  font-size: 48px;
  padding: 0;
  margin: -5px 20px 0 0;
}

.drop-caps.style-05:first-letter {
  color: #00AEEF;
  font-size: 48px;
  padding: 0;
  margin: -5px 20px 0 0;
}

/*---------------------------
	05-12. Forms
---------------------------*/
select, textarea, input {
  display: inline-block;
  font-size: 14px;
  padding: 12px 20px;
  margin-bottom: 10px;
  vertical-align: top;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #ddd;
  font-family: inherit;
  letter-spacing: inherit;
  outline-color: #00aeef;
}

select:focus, textarea:focus, input:focus {
  border-color: #00aeef;
  outline: none;
}

input[type="radio"],
input[type="checkbox"] {
  width: auto;
}

.contact-form [class*="col"] {
  padding: 0px 5px;
}

.form-review label {
  display: block;
  font-weight: 500;
  font-size: 12px;
  color: #111;
}

.form-review input, .form-review textarea {
  padding: 7px 20px;
}

label {
  color: #111;
  font-weight: 500;
}

/*---------------------------
	05-13. Gallery
---------------------------*/
.gallery-item {
  margin-bottom: 30px;
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
}

.gallery-item:hover .gallery-item_overlay {
  background: rgba(34, 34, 34, 0.8);
}

.gallery-item:hover .gallery-item_overlay:before {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.gallery-item_overlay {
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  bottom: 0;
  background: transparent;
}

.gallery-item_overlay:before {
  content: '\e618';
  font-size: 40px;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  margin-top: -50px;
  opacity: 0;
  visibility: hidden;
}

/*---------------------------
	05-14. Icon Boxes
---------------------------*/
.icon-boxes_icon, .icon-boxes_content {
  display: table-cell;
  vertical-align: top;
}

.icon-boxes_icon {
  font-size: 40px;
  color: #111;
  position: relative;
  padding: 5px 20px 0px 0px;
  color: #ccc;
}

.icon-boxes_content {
  padding-top: 10px;
  padding-bottom: 15px;
}

.icon-boxes_content h5, .icon-boxes_content p {
  margin-bottom: 15px;
}

.icon-boxes.reverse {
  text-align: right;
}

.icon-boxes.reverse .icon-boxes_icon {
  padding-right: 0;
  padding-left: 20px;
}

.icon-boxes.style-02 .icon-boxes_icon, .icon-boxes.style-02 .icon-boxes_content {
  vertical-align: middle;
}

.icon-boxes.style-02 .icon-boxes_icon {
  font-size: 60px;
}

.icon-boxes.style-line {
  padding-left: 70px;
}

.icon-boxes.style-line .icon-boxes_icon:before {
  content: '';
  width: 90px;
  height: 1px;
  position: absolute;
  right: calc(100% + 20px);
  top: 50%;
  background: #eee;
}

/*---------------------------
	05-15. Lists
---------------------------*/
.dione-list ol li,
.dione-list ul li {
  line-height: 2.5em;
  margin-left: 30px;
}

.dione-list.type1 li,
.dione-list.type2 li,
.dione-list.type3 li {
  position: relative;
}

.dione-list.type1 li:before,
.dione-list.type2 li:before,
.dione-list.type3 li:before {
  position: absolute;
  font-size: 15px;
  top: 50%;
  left: -25px;
  margin-top: -7px;
}

.dione-list.type1 li:before {
  content: "\e685";
}

.dione-list.type2 li:before {
  content: "\e684";
}

.dione-list.type3 li:before {
  content: "\e66c";
}

.dione-list.type4 li {
  list-style-type: square;
}

.dione-list.type5 li {
  list-style-type: disc;
}

.dione-list.type6 li {
  list-style-type: decimal;
}

/*---------------------------
	05-16. Pagination
---------------------------*/
.pagination-dn {
  text-align: center;
}

.page-numbers li {
  display: inline-block;
  margin: 0px 2px 10px;
}

.page-numbers li .next, .page-numbers li .prev {
  text-transform: uppercase;
  color: #111;
  font-weight: 500;
  font-size: 12px;
}

.page-numbers li .next .fa, .page-numbers li .prev .fa {
  font-size: 14px;
  margin-right: 5px;
  margin-left: 5px;
}

.page-numbers li .next:hover, .page-numbers li .prev:hover {
  color: #00aeef;
}

@media (max-width: 47.9em) {
  .page-numbers li.next, .page-numbers li.prev {
    display: block;
  }
}

@media (min-width: 48em) {
  .page-numbers li .prev {
    margin-right: 10px;
  }
  .page-numbers li .next {
    margin-left: 10px;
  }
}

.page-number {
  display: block;
  text-transform: uppercase;
  color: #111;
  font-weight: 500;
  border: 1px solid #111;
  border-radius: 4px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.page-number:hover, .page-number.current {
  background: #111;
  color: #fff;
}

/*---------------------------
	05-17. Scrollup
---------------------------*/
#scrollUp {
  bottom: 20px;
  right: 20px;
  background: #00aeef;
  color: #fff;
  font-size: 45px;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}

#scrollUp:hover {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/*---------------------------
	05-18. Slider
---------------------------*/
.slider {
  position: relative;
  overflow: hidden;
}

.slider-bg {
  width: 100%;
}

.svg-bottom-large {
  position: absolute;
  top: 100%;
  margin-top: -294px;
  left: 50%;
  margin-left: -1280px;
}

.slider-02 {
  min-height: 400px;
  background: url("../images/home/home2-section1.jpg") center center;
  background-size: cover;
}

.slider-02 .slider-caption {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 10%;
  text-align: center;
  margin-top: 30px;
}

.slider-02 .slider-caption h2 {
  font-size: 32px;
}

@media (min-width: 64em) {
  .slider-02 {
    min-height: 600px;
  }
  .slider-02 .slider-caption h2 {
    font-size: 50px;
  }
}

@media (min-width: 80em) {
  .slider-02 {
    min-height: 1000px;
  }
  .slider-02 .slider-caption h2 {
    font-size: 80px;
  }
}

.slider-06 {
  background: url("../images/home/home6-slider-bg.jpg") top center;
  background-size: cover;
  position: relative;
  min-height: 700px;
}

.slider-06 .slider-caption {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin-left: 30px;
  max-width: 500px;
}

.slider-06 .slider-caption img {
  width: 150px;
}

@media (min-width: 80em) {
  .slider-06 {
    min-height: 900px;
  }
  .slider-06 .slider-caption {
    margin-left: 200px;
  }
  .slider-06 .slider-caption img {
    width: 300px;
  }
}

.slider-07 {
  background: url("../images/home/home7-slider-bg.jpg") top center;
  background-size: cover;
  position: relative;
  min-height: 500px;
  overflow: hidden;
}

.slider-07 .svg-bottom-small {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -1280px;
  z-index: 2;
}

.slider-07 .slider-caption {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  z-index: 3;
  padding: 20px;
}

.slider-07 .slider-caption h2 {
  color: #fff;
  font-size: 40px;
}

.slider-07 .slider-caption img {
  position: relative;
  width: calc(100%/3);
}

@media (min-width: 80em) {
  .slider-07 {
    min-height: 700px;
    overflow: visible;
  }
  .slider-07 .slider-caption {
    margin-top: 20px;
  }
  .slider-07 .slider-caption h2 {
    font-size: 50px;
  }
  .slider-07 .slider-caption img {
    margin-bottom: -100px;
    margin-left: -30px;
    margin-right: -30px;
    width: auto;
  }
}

.slider-10 {
  background: url("../images/home/home10-section1.jpg") center center;
  background-size: cover;
  min-height: 600px;
}

.slider-10 .slider-caption {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  text-align: center;
}

.slider-10 .slider-caption h2 {
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 20px;
}

@media (min-width: 64em) {
  .slider-10 .slider-caption h2 {
    font-size: 70px;
  }
}

.slider-11 h2 {
  font-size: 40px;
  font-weight: 400;
  padding: 70px 0px 150px;
}

@media (min-width: 64em) {
  .slider-11 h2 {
    font-size: 60px;
    padding: 100px 0px 200px;
  }
}

.slider-12 {
  background: url("../images/home/home12-slider-bg.jpg") top left;
  background-size: cover;
  padding-top: 140px;
  padding-bottom: 80px;
  min-height: 500px;
}

.slider-12 h2 {
  font-size: 42px;
  margin-bottom: 20px;
}

.slider-12 p {
  color: #666;
  margin-bottom: 30px;
}

@media (min-width: 48em) {
  .slider-12 .slider-caption {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 100%;
    padding-bottom: 30px;
  }
}

@media (min-width: 64em) {
  .slider-12 h2 {
    font-size: 55px;
    margin-bottom: 30px;
  }
  .slider-12 p {
    margin-bottom: 40px;
  }
}

@media (min-width: 80em) {
  .slider-12 h2 {
    font-size: 70px;
    margin-bottom: 30px;
  }
  .slider-12 p {
    margin-bottom: 40px;
  }
}

/*---------------------------
	05-19. Table
---------------------------*/
.table-dn {
  width: 100%;
  border: 1px solid #ddd;
}

.table-dn th, .table-dn td {
  padding: 20px;
}

.table-dn th {
  font-weight: 500;
  color: #111;
}

.table-dn tr {
  border-bottom: 1px solid #ddd;
}

.table-dn .amount {
  color: #e95249;
}

.table-shopping-cart .product-remove {
  cursor: pointer;
}

.table-shopping-cart .product-remove:hover {
  color: #111;
}

.table-shopping-cart .product-name {
  text-transform: capitalize;
}

.table-shopping-cart .product-name a {
  color: #111;
}

.table-shopping-cart .product-name a:hover {
  color: #00aeef;
}

.table-shopping-cart .product-price,
.table-shopping-cart .product-total {
  color: #e95249;
  font-weight: 500;
}

.table-shopping-cart .product-qty input {
  width: 80px;
  text-align: center;
}

/*---------------------------
	05-20. Tabs
---------------------------*/
.nav-tabs {
  border-bottom: 0;
}

.nav-tabs > li > a {
  border-radius: 0;
  margin-right: 0;
}

.nav > li > a:focus, .nav > li > a:hover {
  background: transparent;
}

.tab-dn1 .nav-tabs > li > a {
  border: 1px solid #eee;
  border-right-color: transparent;
  background: #f7f7f7;
  font-size: 14px;
  padding: 20px 25px;
  color: #212121;
  position: relative;
}

.tab-dn1 .nav-tabs > li > a:before {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #00aeef;
  position: absolute;
  top: -1px;
  left: -1px;
}

.tab-dn1 .nav-tabs > li:last-child > a {
  border-right: 1px solid #eee;
}

.tab-dn1 .nav-tabs > li.active > a, .tab-dn1 .nav-tabs > li.active > a:focus, .tab-dn1 .nav-tabs > li.active > a:hover {
  color: #212121;
  background: #f7f7f7;
  border-color: #eee;
}

.tab-dn1 .nav-tabs > li.active > a:before, .tab-dn1 .nav-tabs > li.active > a:focus:before, .tab-dn1 .nav-tabs > li.active > a:hover:before {
  content: '';
  display: block;
  width: calc(100% + 2px);
}

.tab-dn1 .tab-content {
  border: 1px solid #eee;
  padding: 50px 35px 40px;
}

.tab-dn2 .nav-tabs > li > a {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #111;
  padding: 6px 20px;
  border: 1px solid transparent;
  border-right: 1px solid #ddd;
}

.tab-dn2 .nav > li > a:focus, .tab-dn2 .nav > li > a:hover {
  color: #00aeef;
  border-color: transparent;
  border-right-color: #ddd;
}

.tab-dn2 .nav-tabs > li:first-child > a {
  padding-left: 0;
}

.tab-dn2 .nav-tabs > li:last-child > a {
  border-right: 0;
}

.tab-dn2 .nav-tabs > li.active > a, .tab-dn2 .nav-tabs > li.active > a:focus, .tab-dn2 .nav-tabs > li.active > a:hover {
  background: transparent;
  color: #00aeef;
}

.tab-dn2 .tab-content {
  padding: 30px 0px;
}

.tab-dn3 .nav-tabs {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}

.tab-dn3 .nav-tabs > li {
  float: none;
}

.tab-dn3 .nav-tabs > li:first-child > a {
  border-top-color: #ddd;
}

.tab-dn3 .nav-tabs > li > a {
  font-size: 12px;
  font-weight: 500;
  color: #111;
  border-bottom-color: #ddd;
  padding: 15px;
}

.tab-dn3 .nav > li > a:focus, .tab-dn3 .nav > li > a:hover,
.tab-dn3 .nav-tabs > li.active > a, .tab-dn3 .nav-tabs > li.active > a:focus, .tab-dn3 .nav-tabs > li.active > a:hover {
  background: #0076a3;
  color: #fff;
}

@media (max-width: 47.9em) {
  .nav-tabs > li {
    float: none;
  }
  .tab-dn2 .nav-tabs > li:first-child > a {
    padding-left: 20px;
  }
  .tab-dn2 .nav-tabs > li > a,
  .tab-dn2 .nav > li > a:focus, .nav > li > a:hover {
    border-left-color: #ddd;
    border-right-color: transparent;
  }
}

@media (min-width: 64em) {
  .tab-dn3 .nav-tabs, .tab-dn3 .tab-content {
    display: inline-block;
    vertical-align: top;
  }
  .tab-dn3 .nav-tabs {
    width: 270px;
    margin-right: 20px;
    margin-bottom: 0;
  }
  .tab-dn3 .tab-content {
    width: calc(100% - 300px);
  }
}

/*---------------------------
	05-21. Testimonials
---------------------------*/
.quote {
  background: url("../images/testimonial_bg_skin1.jpg") top left;
  background-size: cover;
  min-height: 500px;
  padding: 115px 0px;
}

.quote-item_icon {
  font-size: 60px;
  color: #00aeef;
  margin-bottom: 20px;
}

.quote-item_text {
  color: #fff;
  font-size: 18px;
  line-height: 1.7em;
  margin-bottom: 30px;
}

.quote-item .author span {
  text-transform: uppercase;
  color: #00aeef;
  display: block;
}

.testimonials.skin_bg-primary-color {
  background: url("../images/testimonial_bg_skin2.jpg") top left;
  background-size: cover;
  padding: 100px 0px;
}

.testimonials.style-03 {
  background: url("../images/home/home7-testi-bg.jpg") top left;
  background-size: cover;
  padding: 100px 0px;
}

.testimonials img {
  position: relative;
  margin-top: -85px;
}

.testimonials .testi-talk {
  text-align: center;
  margin: 0 auto 30px;
}

.testimonials .testi-talk i, .testimonials .testi-talk p {
  color: #fff;
}

.testimonials .testi-talk i {
  margin-bottom: 0;
  font-size: 60px;
}

.testimonials .testi-talk p {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.2em;
  letter-spacing: 0.03em;
}

.testimonials .quote-item {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.testimonials .quote-item_wrapper {
  background: #fff;
  border-radius: 6px;
  text-align: center;
  padding: 50px 30px;
}

.testimonials .quote-item_text {
  color: #999;
  font-size: 16px;
}

.testimonials .quote-item .author {
  font-style: inherit;
  font-size: 12px;
}

.testimonials .quote-item .author span {
  font-size: 14px;
}

.testimonials .quote-item:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 17.5px 15px 17.5px 0px;
  border-right-color: #fff;
  position: absolute;
  top: calc(50% - 7.5px);
  left: 0px;
}

.testimonials .owl-dots {
  position: absolute;
  top: 50%;
  right: 0px;
  margin-top: -20px;
}

.testimonials .owl-dot {
  display: block;
  margin-bottom: 5px;
}

.owl-carousel .owl-item img {
  width: auto;
}

.owl-dot {
  width: 10px;
  height: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.owl-dot.active {
  background: #fff;
  border-color: #fff;
}

@media (min-width: 48em) {
  .quote-item_text {
    font-size: 24px;
  }
}

@media (min-width: 64em) {
  .testimonials .owl-dots {
    right: -30px;
  }
}

@media (min-width: 80em) {
  .testimonials.skin_bg-primary-color {
    padding: 0;
  }
  .testimonials.skin_bg-primary-color .testi-talk {
    position: absolute;
    top: 50%;
    left: -72px;
    margin-top: -80px;
  }
  .testimonials {
    padding: 0;
  }
  .testimonials .testi-talk p {
    font-size: 34px;
  }
  .testimonials .quote-item_wrapper {
    padding: 75px 60px;
  }
  .testimonials .quote-item_text {
    font-size: 18px;
  }
}

/*---------------------------
	05-22. Text Block
---------------------------*/
.text-block {
  padding: 60px 50px;
  margin-bottom: 30px;
}

.text-block h5 {
  margin-bottom: 20px;
}

.text-block_bg-light-gray-color {
  background: #f5f5f5;
}

.text-block_bg-secondary-color {
  background: #0076a3;
}

.text-block_bg-secondary-color * {
  color: #fff;
}

.text-block_bg-dark-color {
  background: #444;
}

.text-block_bg-dark-color h5 {
  color: #fff;
}

.text-block_border-gray-color {
  border: 1px solid #ddd;
}

/*---------------------------
	05-23. Title Background
---------------------------*/
.title-bg {
  position: relative;
  overflow-x: hidden;
}

.title-bg_content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
}

.title-bg_content h2 {
  margin-top: 35px;
  margin-bottom: 20px;
  color: #fff;
}

.title-bg.style-01 {
  background: url("../images/home/home3-section4_bg.jpg") top left;
  background-size: cover;
  min-height: 500px;
}

.title-bg.style-01:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #111;
  opacity: 0.9;
}

.title-bg.style-02 {
  background: url("../images/home/home4-title-bg2.jpg") center center;
  background-size: cover;
  min-height: 600px;
}

.title-bg.style-02 h2 {
  font-size: 60px;
  margin-bottom: 30px;
}

@media (max-width: 47.9em) {
  .title-bg.style-02 h2 {
    font-size: 32px;
  }
}

/*---------------------------
	05-24. Typography
---------------------------*/
.heading-1 {
  font-weight: 300;
  font-size: 48px;
}

.heading-2 {
  font-weight: 300;
  font-size: 36px;
}

.heading-3 {
  font-weight: 300;
  font-size: 24px;
}

.heading-4 {
  font-weight: 500;
  font-size: 18px;
}

.heading-5, .page-blog-single .author-info .author-name {
  font-weight: 500;
  font-size: 14px;
}

.heading-6, .blog-entry .author {
  font-weight: 500;
  font-size: 12px;
}

.block-quote_style1 {
  color: #999;
  border-left: 2px solid #00aeef;
  padding: 5px 15px;
  font-size: 14px;
}

.block-quote_style2 {
  color: #111;
  border-color: transparent;
  padding: 5px 15px 5px 90px;
  font-size: 14px;
  position: relative;
}

.block-quote_style2:before {
  content: '';
  display: block;
  width: 80px;
  height: 1px;
  background: #ddd;
  position: absolute;
  top: 50%;
  left: 0;
}

.block-quote_style3 {
  color: #999;
  background: #f5f5f5;
  border-left: 2px solid #111;
  padding: 15px 25px;
  font-size: 14px;
}

.highlight-text-blue {
  background: #00aeef;
  color: #fff;
}

.highlight-text-gray {
  background: #f5f5f5;
}

.highlight-text-black {
  background: #111;
}

.no-margin {
  margin: 0;
}

.padding-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.padding-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.padding-top-xsmall {
  padding-top: 20px;
}

.padding-top-small {
  padding-top: 30px;
}

.padding-top-medium {
  padding-top: 50px;
}

.padding-top-large {
  padding-top: 70px;
}

.padding-top-xlarge {
  padding-top: 90px;
}

.padding-bottom-xsmall {
  padding-bottom: 20px;
}

.padding-bottom-small {
  padding-bottom: 30px;
}

.padding-bottom-medium {
  padding-bottom: 50px;
}

.padding-bottom-large {
  padding-bottom: 70px;
}

.padding-bottom-xlarge {
  padding-bottom: 90px;
}

.margin-top-xsmall {
  margin-top: 20px;
}

.margin-top-small {
  margin-top: 30px;
}

.margin-top-medium {
  margin-top: 50px;
}

.margin-top-large {
  margin-top: 70px;
}

.margin-top-xlarge {
  margin-top: 90px;
}

.margin-bottom-xsmall {
  margin-bottom: 20px;
}

.margin-bottom-small {
  margin-bottom: 30px;
}

.margin-bottom-medium {
  margin-bottom: 50px;
}

.margin-bottom-large {
  margin-bottom: 70px;
}

.margin-bottom-xlarge {
  margin-bottom: 90px;
}

/*==============================================================
	06. CONTENT
==============================================================*/
/*---------------------------
	06-01. About
---------------------------*/
/* ABOUT 01 */
.about-1 .page-header {
  height: 700px;
  background: url("../images/about-big-bg-1.jpg") top left;
  background-size: cover;
  position: relative;
}

.about-1 .page-header:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #111;
  opacity: 0.9;
}

.about-1 .page-header_content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
}

.about-1 .page-header h2 {
  margin-top: 50px;
  margin-bottom: 30px;
  color: #fff;
}

.about-1 .page-header h2 span {
  color: #00aeef;
}

.about-1 .page-header p {
  color: #fff;
  padding: 0px 20px;
}

@media (min-width: 48em) {
  .about-1 .page-header p {
    width: 65%;
    margin: auto;
  }
}

.about-banner {
  background: url("../images/about/banner-bg.jpg") top left;
  background-size: cover;
  min-height: 600px;
  max-width: 700px;
  position: relative;
  z-index: 999;
}

.about-banner_wrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0px 30px;
}

.about-banner h2 {
  color: #fff;
  font-size: 32px;
  margin-bottom: 35px;
}

.about-banner p {
  color: #fff;
  margin-bottom: 50px;
}

.about-banner .bttn {
  margin-right: 5px;
  margin-bottom: 10px;
}

@media (min-width: 48em) {
  .about-banner_wrapper {
    padding: 0px 70px;
  }
  .about-banner h2 {
    font-size: 36px;
  }
}

@media (min-width: 80em) {
  .about-banner {
    margin-top: -100px;
    width: 700px;
    height: 600px;
    float: right;
  }
  .about-banner_wrapper {
    padding: 0px 100px;
  }
}

.choose-us {
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
}

.choose-us .section-header {
  position: relative;
  z-index: 999;
}

.choose-us .icon-boxes {
  z-index: 999;
  background: rgba(255, 255, 255, 0.5);
}

.choose-us_img {
  position: absolute;
  top: 0px;
  right: -200px;
}

@media (min-width: 80em) {
  .choose-us_img {
    top: -80px;
    right: 0;
  }
}

@media (min-width: 80em) {
  .choose-us {
    overflow: visible;
  }
}

/* ABOUT 02 */
.about-2 .page-header {
  padding: 100px 0px 150px;
  background: url("../images/about/page-header_ab2_bg.png") top left;
  position: relative;
  z-index: 1;
}

.about-2 .progress-box {
  margin-top: -100px;
}

.page-header_about-text h2 {
  font-size: 48px;
  text-transform: inherit;
  margin-bottom: 25px;
}

.page-header_about-text h2 span {
  color: #00aeef;
}

.page-header_about-text h4, .page-header_about-text p {
  margin-bottom: 25px;
}

/* OUR TEAM */
.our-team_item {
  position: relative;
  margin-bottom: 30px;
}

.our-team_item:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0;
}

.our-team_item:hover::before {
  opacity: 0.9;
}

.our-team_item:hover .our-team_item-content {
  opacity: 1;
}

.our-team_item-content {
  text-align: center;
  padding: 15px;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.our-team_item-content h5 {
  color: #00aeef;
  margin-bottom: 10px;
}

.our-team_item-content p {
  margin-bottom: 30px;
}

.our-team_item-content .social-link a {
  color: #fff;
  border: 1px solid #777;
  border-radius: 4px;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
}

.our-team_item-content .social-link a:hover {
  background: #00aeef;
  border-color: #00aeef;
}

.our-team_item.style-02 .our-team_item-content {
  top: auto;
  bottom: 0;
  transform: none;
  padding: 30px;
  text-align: left;
}

.our-team_item.style-02 .our-team_item-content h4 {
  color: #fff;
  margin-bottom: 5px;
}

.our-team_item.style-02 .our-team_item-content p {
  color: #fff;
  margin-bottom: 0;
}

/* ABOUT 03 */
@media (max-width: 63.9em) {
  .about-3 .page-header_about-text {
    padding: 50px 30px;
  }
}

@media (min-width: 80em) {
  .about-3 .page-header_about-text {
    padding: 0px 45px;
  }
}

/* ABOUT 04 */
.about-4 .page-header {
  background: url("../images/about-big-bg-2.jpg") top left;
  background-size: cover;
  position: relative;
}

.about-4 .page-header:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.8;
}

.about-4 .page-header_content {
  padding-top: 150px;
  padding-bottom: 150px;
}

.about-4 .page-header_content h2 {
  color: #fff;
  font-size: 50px;
  max-width: 794px;
  margin: 0 auto;
  line-height: 1em;
}

.banner-ab4 {
  background: url("../images/about/banner-ab4_bg.jpg") top left;
  background-size: cover;
  position: relative;
  text-align: center;
  min-height: 400px;
}

.banner-ab4:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0076a3;
  opacity: 0.8;
}

.banner-ab4 h2 {
  color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  padding: 15px;
}

.do-you-want {
  background: #00aeef;
  padding: 50px 0px 40px;
}

.do-you-want h3 {
  text-transform: inherit;
  color: #fff;
  margin-bottom: 10px;
}

.do-you-want .bttn {
  margin: 0px 0px 10px 5px;
}

@media (min-width: 80em) {
  .about-4 .page-header_content {
    padding-top: 300px;
    padding-bottom: 300px;
  }
  .about-4 .page-header_content h2 {
    font-size: 80px;
  }
}

/*---------------------------
	06-02. Blog
---------------------------*/
/* PAGE-HEADER */
.page-blog-grid-boxed .page-header,
.page-blog-grid-fullwidth .page-header {
  border-bottom: none;
  margin-top: 120px;
  margin-bottom: 60px;
}

.page-blog-grid-boxed .page-header h2,
.page-blog-grid-fullwidth .page-header h2 {
  text-transform: inherit;
  margin-top: 35px;
  margin-bottom: 30px;
}

/* TAG CLOUD */
.tagcloud a {
  border: 1px solid #eee;
  display: inline-block;
  padding: 9px 17px;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-right: 5px;
}

.tagcloud a:hover {
  border-color: #00aeef;
  color: #00aeef;
}

/* BLOG-ENTRY-CONTENT */
.blog-entry {
  margin-bottom: 30px;
}

.blog-entry-title a {
  color: #111;
  margin: 15px 0px;
  display: block;
  line-height: 1.3em;
}

.blog-entry-content p {
  margin-bottom: 15px;
}

.blog-entry .blog-quote {
  background: #111;
  border: none;
  font-size: 22px;
  color: #fff;
  padding: 41px;
  text-align: center;
}

.blog-entry .blog-quote span {
  display: block;
  color: #00aeef;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 15px;
}

.blog-entry .date {
  font-size: 12px;
}

.blog-entry .author {
  color: #111;
}

.blog-entry .author img {
  margin-right: 5px;
  border-radius: 50%;
}

.blog-entry .postcontent-grid {
  background: #fff;
  padding: 35px;
}

.post-thumb img {
  width: 100%;
}

.fluid-width-video-wrapper,
.fluid-width-media-wrapper {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}

.fluid-width-video-wrapper iframe,
.fluid-width-media-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.fluid-width-media-wrapper {
  padding-top: 40%;
}

@media (max-width: 63.9em) {
  .page-blog-grid-fullwidth .sidebar {
    margin-top: 50px;
  }
}

/* BLOG GRID LAYOUT */
.page-blog-grid-fullwidth .blog-entry-wrapper, .page-blog-grid-boxed .blog-entry-wrapper {
  background: #f5f5f5;
  overflow: hidden;
  padding: 30px;
}

/* BLOG SINGLE */
.page-blog-single .blog-entry .post-thumb {
  margin-bottom: 50px;
}

.page-blog-single .blog-entry-title {
  text-transform: inherit;
  margin-top: 15px;
  margin-bottom: 50px;
}

.page-blog-single .blog-entry blockquote {
  margin-top: 35px;
  margin-bottom: 40px;
}

.page-blog-single .blog-entry-footer {
  border-top: 1px solid #ddd;
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.page-blog-single .blog-entry-footer h5 {
  display: inline-block;
  font-size: 12px;
  margin-right: 20px;
}

.page-blog-single .blog-entry-footer .social-menu {
  display: inline-block;
}

.page-blog-single .blog-entry-footer .tags a {
  font-size: 12px;
}

.page-blog-single .author-info {
  background: #f5f5f5;
  padding: 40px;
}

.page-blog-single .author-info_avatar {
  margin-bottom: 20px;
}

.page-blog-single .author-info .author-name {
  text-transform: uppercase;
  color: #111;
  margin-bottom: 15px;
}

.page-blog-single .author-info .author-text {
  margin-bottom: 15px;
}

.page-blog-single .social-menu li {
  margin-right: 15px;
}

.page-blog-single .social-menu li:last-child {
  margin-right: 0;
}

@media (min-width: 64em) {
  .page-blog-single .col-md-9 {
    padding-left: 0;
  }
  .page-blog-single .col-md-3 {
    padding-right: 0;
  }
  .page-blog-single .author-info_avatar {
    float: left;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* POST NAVIGATION */
.post-navigation {
  padding: 45px 0px;
}

.post-navigation .nav-previous a, .post-navigation .nav-next a {
  position: relative;
}

.post-navigation .nav-previous a:before, .post-navigation .nav-next a:before {
  font-size: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
}

.post-navigation .nav-previous a {
  padding-left: 15px;
}

.post-navigation .nav-previous a:before {
  content: '\e686';
  left: -8px;
}

.post-navigation .nav-next a {
  padding-right: 15px;
}

.post-navigation .nav-next a:before {
  content: '\e684';
  right: -11px;
}

/* COMMENTS */
.comment-area {
  background: #f5f5f5;
  padding: 50px 45px;
}

.comment-area .metadata {
  margin-bottom: 10px;
}

.comment > div {
  padding-bottom: 30px;
  border-bottom: 1px solid #fff;
  margin-bottom: 30px;
}

.comment-list > .comment:last-child > div {
  border-bottom-color: #ddd;
  margin-bottom: 50px;
}

.comment-list_children .comment > div {
  padding-left: 50px;
}

.comment-title {
  margin-bottom: 60px;
}

.comment-author {
  margin-bottom: 20px;
}

.comment-author img {
  border-radius: 100%;
}

.comment-reply {
  color: #00aeef;
  font-size: 12px;
}

@media (min-width: 48em) {
  .comment-area {
    padding: 70px 50px;
  }
  .comment-list_children .comment > div {
    padding-left: 100px;
  }
  .comment-author {
    float: left;
    margin-right: 30px;
  }
  .comment-reply {
    float: right;
    margin-top: -10px;
  }
}

.reply-title {
  margin-bottom: 35px;
}

.comment-form .row {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.comment-form [class*="col"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/*---------------------------
	06-03. Contact
---------------------------*/
.page-contact .social-menu .menu li {
  margin-right: 10px;
}

.page-contact .social-menu .menu li a {
  color: #444;
}

.page-contact .social-menu .menu li a:before {
  font-size: 18px;
}

.page-contact .social-menu .menu li a:hover {
  color: #00aeef;
}

.map {
  display: block;
  width: 100%;
  height: 570px;
}

@media (max-width: 79.9em) {
  .map {
    height: 450px;
  }
}

@media (max-width: 63.9em) {
  .map {
    height: 300px;
  }
}

/*---------------------------
	06-04. Elements
---------------------------*/
.page-elements_typography .heading-1, .page-elements_typography .heading-2, .page-elements_typography .heading-3, .page-elements_typography .heading-4, .page-elements_typography .heading-5, .page-elements_typography .page-blog-single .author-info .author-name, .page-blog-single .author-info .page-elements_typography .author-name, .page-elements_typography .heading-6, .page-elements_typography .blog-entry .author, .blog-entry .page-elements_typography .author, .page-elements_typography blockquote {
  margin-bottom: 50px;
}

.page-elements_typography p {
  color: #999;
}

.page-elements_buttons h5 {
  margin-bottom: 40px;
}

.page-elements_buttons .bttn {
  margin-bottom: 20px;
  margin-right: 20px;
}

.page-elements_buttons .link-text {
  margin-bottom: 30px;
}

.page-header {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
  border-bottom: none;
}

.section-header {
  text-align: center;
}

.section-header h2 {
  text-transform: inherit;
  margin-top: 40px;
}

/*---------------------------
	06-05. Home
---------------------------*/
.hover-line:hover rect:nth-of-type(1) {
  -webkit-animation: line-dn 0.5s linear infinite alternate;
  -moz-animation: line-dn 0.5s linear infinite alternate;
  -o-animation: line-dn 0.5s linear infinite alternate;
  animation: line-dn 0.5s linear infinite alternate;
}

.hover-line:hover rect:nth-of-type(3) {
  -webkit-animation: line-dn2 0.5s linear infinite alternate;
  -moz-animation: line-dn2 0.5s linear infinite alternate;
  -o-animation: line-dn2 0.5s linear infinite alternate;
  animation: line-dn2 0.5s linear infinite alternate;
}

/* Line-dn */
@-webkit-keyframes line-dn {
  from {
    width: 34px;
    x: 0px;
  }
  to {
    width: 11.3334px;
    x: 11px;
  }
}

@-moz-keyframes line-dn {
  from {
    width: 34px;
    x: 0px;
  }
  to {
    width: 11.3334px;
    x: 11px;
  }
}

@-o-keyframes line-dn {
  from {
    width: 34px;
    x: 0px;
  }
  to {
    width: 11.3334px;
    x: 11px;
  }
}

@keyframes line-dn {
  from {
    width: 34px;
    x: 0px;
  }
  to {
    width: 11.3334px;
    x: 11px;
  }
}

/* Line-dn2 */
@-webkit-keyframes line-dn2 {
  from {
    width: 11.3334px;
    x: 11.0503px;
  }
  to {
    width: 34px;
    x: 0px;
  }
}

@-moz-keyframes line-dn2 {
  from {
    width: 11.3334px;
    x: 11.0503px;
  }
  to {
    width: 34px;
    x: 0px;
  }
}

@-o-keyframes line-dn2 {
  from {
    width: 11.3334px;
    x: 11.0503px;
  }
  to {
    width: 34px;
    x: 0px;
  }
}

@keyframes line-dn2 {
  from {
    width: 11.3334px;
    x: 11.0503px;
  }
  to {
    width: 34px;
    x: 0px;
  }
}

/* HOME 01 */
.home-01 .page-content {
  overflow: hidden;
}

.home-01 .header,
.home-02 .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.header.white-style #primary-menu .menu > ul > li > a,
.header.white-style #primary-menu .menu > ul > li:hover:before, .header.white-style #primary-menu .menu > ul > li.active:before,
.header.white-style #primary-menu .menu > ul > li:hover:after, .header.white-style #primary-menu .menu > ul > li.active:after,
.header.white-style .mini-cart_button,
.header.white-style .search-icon a {
  color: #fff;
}

.header.white-style #primary-menu .sub-menu,
.header.white-style .cart-list {
  border-top-color: #fff;
}

.new-update {
  padding-bottom: 30px;
}

.new-update .blog-entry-title a {
  margin: 30px 0px;
}

.new-update .blog-entry-content p {
  margin-bottom: 30px;
}

.new-update .post {
  margin-bottom: 70px;
}

@media (max-width: 63.9em) {
  .home-01 .logo, .home-02 .logo {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .home-01 .hover-line h1 {
    font-size: 36px;
  }
}

@media (min-width: 80em) {
  .we-are-results {
    max-width: 550px;
    padding-left: 30px;
  }
  .we-can-do {
    max-width: 600px;
    float: right;
    padding-right: 75px;
  }
}

/* HOME 02 */
.home-02 .slide-caption {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 10%;
  text-align: center;
  margin-top: 30px;
}

@media (min-width: 80em) {
  .home-02 .icon-boxes {
    max-width: 600px;
  }
  .home-02 .section-5 {
    overflow: hidden;
  }
  .home-02 .section-5_text {
    max-width: 500px;
  }
  .home-02 .section-5_text:before {
    content: '';
    display: block;
    width: 536px;
    height: 1000px;
    position: absolute;
    top: -400px;
    left: 125px;
    background: #f7f7f7;
    z-index: -1;
  }
}

/* HOME 03 */
.home-03 .header-wrapper {
  min-height: 400px;
  background: url("../images/home/home3-section1.png") top left;
}

.home-03 .slide-caption {
  padding: 50px 0px 30px;
}

.home-03 .slide-caption h2 {
  margin-bottom: 30px;
}

.home-03 .line-vertical {
  background: #bbb;
}

.home-03 .home3-section1 .icon-boxes {
  padding-top: 20px;
  min-height: 400px;
}

.home-03 .we-bring-you {
  padding: 70px 15px;
  max-width: 662px;
}

.home-03 .we-bring-you .bttn {
  margin-bottom: 10px;
  margin-right: 6px;
}

.home-03 .home3-section1_img {
  margin-top: -30px;
}

.home-03 .progress-img {
  width: 100%;
  text-align: center;
  margin-top: 70px;
}

@media (min-width: 48em) {
  .home-03 .slide-caption {
    padding: 120px 0px 30px;
  }
}

@media (min-width: 64em) {
  .home-03 .slide-caption {
    padding: 150px 0px 30px;
  }
  .home-03 .slide-caption h2 {
    margin-bottom: 30px;
    font-size: 50px;
  }
  .home-03 .line-vertical {
    height: 160px;
  }
  .home-03 .home3-section1_img {
    position: absolute;
    top: 50%;
    left: -140px;
    margin-top: -240px;
    z-index: 9;
  }
  .home-03 .home3-section1 .icon-boxes {
    padding-right: 150px;
    padding-left: 20px;
    min-height: 400px;
  }
  .home-03 .we-bring-you {
    padding: 0px 0px 0px 150px;
    max-width: 662px;
  }
}

@media (min-width: 80em) {
  .home-03 .slide-caption {
    padding: 180px 0px 50px;
  }
  .home-03 .slide-caption h2 {
    margin-bottom: 30px;
    font-size: 80px;
  }
  .home-03 .home3-section1 .icon-boxes {
    padding-right: 180px;
  }
  .home-03 .we-bring-you {
    padding-left: 180px;
  }
  .home-03 .progress-box {
    position: relative;
    padding-bottom: 150px;
  }
  .home-03 .progress-item {
    padding-right: 30px;
  }
  .home-03 .progress-img {
    width: 851px;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 3;
  }
  .home-03 .section-5 {
    position: relative;
    margin-top: -110px;
    z-index: 2;
    background: #fff;
  }
}

/* HOME 04 */
.home-04 .intro {
  background: url("../images/home/home4-section1-bg.png") top center;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}

.home-04 .intro-title {
  text-transform: inherit;
  font-size: 48px;
  padding-left: 20px;
}

.home-04 .intro-item_number {
  font-size: 70px;
  width: 70px;
  text-align: center;
  color: #111;
  float: left;
  line-height: 1;
  margin-top: -15px;
}

.home-04 .intro-item_content {
  margin-left: 70px;
}

.home-04 .intro-item_content h5 {
  margin-bottom: 15px;
}

.home-04 .intro-item_content p {
  margin-bottom: 15px;
}

@media (min-width: 48em) {
  .home-04 .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
}

@media (min-width: 80em) {
  .home-04 .intro-title {
    padding-left: 0px;
    margin-left: -50px;
  }
  .home-04 .intro-title .space {
    padding-left: 70px;
  }
}

/* HOME 05 */
.home-05 .page-content,
.home-05 .header,
.home-05 .footer {
  padding-left: 50px;
  padding-right: 50px;
}

.home-05 .copy-right {
  text-transform: uppercase;
  color: #111;
  padding: 45px 15px 40px;
  text-align: center;
}

.home-05 .page-content {
  position: relative;
}

.home-05 .page-content:before, .home-05 .page-content:after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  background: #ccc;
  position: absolute;
  top: 50%;
  margin-top: 10px;
  z-index: 9999;
}

.home-05 .page-content:before {
  left: 40px;
}

.home-05 .page-content:after {
  right: 40px;
}

.home-05 .section-name,
.home-05 .section-order {
  text-transform: uppercase;
  color: #111;
  font-size: 18px;
  position: absolute;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  line-height: 1em;
  white-space: nowrap;
  display: inline-block;
  width: 200px;
  text-align: center;
}

.home-05 .section-name {
  webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  right: calc(100% - 120px);
}

.home-05 .section-order {
  webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  left: calc(100% - 120px);
}

.home-05 .icon-boxes {
  background: #f7f7f7;
  padding: 50px 20px;
  margin-top: 30px;
}

.home-05 .icon-boxes_icon {
  color: #111;
  font-size: 30px;
}

.home-05 .our-works_item {
  float: left;
  width: 100%;
  position: relative;
}

.home-05 .our-works_item:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #111;
  opacity: 0;
  visibility: hidden;
}

.home-05 .our-works_item img {
  width: 100%;
}

.home-05 .our-works_item:hover:before {
  opacity: 0.8;
  visibility: visible;
}

.home-05 .our-works_item:hover .our-works_overlay {
  opacity: 1;
  visibility: visible;
}

.home-05 .our-works_overlay {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  text-align: center;
  opacity: 0;
  visibility: hidden;
}

.home-05 .our-works_overlay a {
  color: #fff;
  font-weight: 400;
}

.home-05 .get-in-touch {
  background: #f7f7f7;
}

@media (max-width: 47.9em) {
  .home-05 .icon-boxes_icon {
    padding-right: 0;
  }
}

@media (min-width: 48em) {
  .home-05 .our-works_item {
    width: 20%;
  }
}

@media (min-width: 64em) {
  .home-05 .page-content,
  .home-05 .header,
  .home-05 .footer {
    padding-left: 100px;
    padding-right: 100px;
  }
  .home-05 .section-name {
    right: calc(100% - 150px);
  }
  .home-05 .section-order {
    left: calc(100% - 150px);
  }
  .home-05 .page-content:before, .home-05 .page-content:after {
    width: 80px;
  }
  .home-05 .page-content:before {
    left: 80px;
  }
  .home-05 .page-content:after {
    right: 80px;
  }
  .home-05 .digital-specialist {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .home-05 .who-we-are:before {
    content: '';
    display: block;
    width: 30%;
    height: 100%;
    background: #f7f7f7;
    position: absolute;
    top: 0;
    right: 100px;
  }
  .home-05 .what-we-do {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .home-05 .what-we-do:before {
    content: '';
    display: block;
    width: 20%;
    height: 100%;
    background: #f7f7f7;
    position: absolute;
    top: 0;
    left: 100px;
  }
  .home-05 .effective {
    padding-left: 80px;
  }
  .home-05 .icon-boxes {
    background: #f7f7f7;
    padding: 100px 50px;
  }
  .home-05 .our-works_item {
    width: 20%;
  }
  .home-05 .get-in-touch {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (min-width: 80em) {
  .home-05 .who-we-are:before {
    width: 38%;
  }
  .home-05 .get-in-touch {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

/* HOME 06 */
.home-06 .header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}

.home-06 .svg-plus {
  margin-top: 20px;
  margin-bottom: 10px;
}

.intro-text {
  padding: 100px 20px;
}

.intro-text h2 {
  line-height: 1.6em;
  font-size: 24px;
}

.intro-text .read-more {
  color: #fff;
  text-decoration: underline;
}

@media (min-width: 64em) {
  .intro-text {
    padding: 250px 20px;
  }
  .intro-text h2 {
    line-height: 1.6em;
    font-size: 36px;
  }
  .intro-text .read-more {
    color: #fff;
    text-decoration: underline;
  }
  .home-06 .folio-main-item {
    padding-left: 35px;
    padding-right: 35px;
  }
  .home-06 .folio-title {
    color: #111;
  }
  .home-06 .folio-title:hover {
    color: #00aeef;
  }
}

/* HOME 07 */
.home-07 .section-1 {
  overflow: hidden;
}

.home-07 .effective {
  background: #f7f7f7;
  padding: 70px 50px;
}

.home-07 .img-sec1-1 {
  position: relative;
  margin-bottom: -30px;
  z-index: 2;
}

.home-07 .img-sec1-2 {
  position: relative;
  margin-left: -50px;
  z-index: 2;
}

.home-07 .digital-specialist .bttn {
  margin: 0px 6px 10px 0px;
}

@media (min-width: 64em) {
  .home-07 .header {
    padding-left: 35px;
    padding-right: 35px;
  }
  .home-07 footer {
    margin-left: 50px;
    margin-right: 50px;
  }
  .home-07 .page-content {
    padding-left: 50px;
    padding-right: 50px;
  }
  .home-07 .folio-main-3col {
    margin-left: -30px;
    margin-right: -30px;
  }
  .home-07 .digital-specialist {
    position: relative;
  }
  .home-07 .digital-specialist:before {
    content: '';
    display: block;
    width: 80%;
    height: 250%;
    top: -90%;
    left: 35%;
    position: absolute;
    background: #f7f7f7;
    z-index: -1;
  }
  .home-07 .effective {
    padding: 100px 80px;
  }
}

@media (min-width: 80em) {
  .home-07 .folio-main-3col {
    margin-left: -50px;
    margin-right: -50px;
  }
  .home-07 .folio-main-grid .folio-main-item {
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 70px;
  }
}

/* HOME 08 */
.home-08 .slider {
  background: url("../images/home/home8-section1.jpg") center center;
  padding: 150px 0px;
}

.home-08 .slider h2 {
  font-size: 50px;
  font-weight: 600;
  text-transform: inherit;
  padding-left: 12%;
}

.home-08 .see-more-work {
  background: url("../images/home/home8-see-more-bg.jpg") center center;
}

@media (min-width: 48em) {
  .home-08 .slider {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

@media (min-width: 64em) {
  .home-08 .slider {
    padding-top: 250px;
    padding-bottom: 250px;
  }
  .home-08 .slider h2 {
    font-size: 70px;
  }
  .home-08 .folio-main-grid .folio-main-item {
    padding: 0px 35px;
    margin-bottom: 70px;
  }
}

/* HOME 09 */
.home-09 .detail-wrapper {
  position: relative;
  min-height: 700px;
  background-size: cover;
}

.home-09 .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.home-09 .detail-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 50%;
  margin-left: -145px;
  width: 290px;
  background: #fff;
  text-align: center;
  padding: 50px 20px;
}

.home-09 .detail-content_tag {
  margin-bottom: 20px;
}

.home-09 .detail-content_tag a {
  color: #111;
}

.home-09 .detail-content_tag a:hover {
  color: #00aeef;
}

.home-09 .detail-content_title {
  line-height: 1.4em;
  padding: 50px 0px;
  font-size: 32px;
}

.line-short {
  width: 40px;
  height: 3px;
  background: #00aeef;
  margin: 0px auto;
}

@media (min-width: 48em) {
  .home-09 .detail-wrapper {
    min-height: 800px;
  }
  .home-09 .detail-content {
    width: 400px;
    margin-left: -200px;
    padding: 30px;
  }
  .home-09 .detail-content_title {
    padding: 70px 0px;
    font-size: 36px;
  }
}

@media (min-width: 64em) {
  .home-09 .detail-content {
    margin-left: -250px;
    width: 500px;
    padding: 60px;
  }
  .home-09 .detail-content_title {
    padding: 70px 0px;
    font-size: 48px;
  }
}

/* HOME 10 */
.home-10 .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.home-10 .shop-banner {
  margin-top: 30px;
  position: relative;
}

.home-10 .shop-banner_content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  text-align: center;
  padding-left: 50%;
}

.home-10 .shop-banner_content h3 {
  font-weight: 500;
  margin-bottom: 5px;
}

.home-10 .shop-banner_link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

/* HOME 12 */
.home-12 .header-wrapper {
  position: relative;
}

.home-12 .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

/*---------------------------
	06-06. Our Services
---------------------------*/
.page-our-services .page-header {
  background: url("../images/our-services/our-services_page-header_bg.jpg") top left;
  background-size: cover;
  height: 800px;
  position: relative;
}

.page-our-services .page-header:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.85;
}

.page-our-services .page-header_content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
}

.page-our-services .page-header .heading-5, .page-our-services .page-header .page-blog-single .author-info .author-name, .page-blog-single .author-info .page-our-services .page-header .author-name {
  margin-top: 65px;
  margin-bottom: 25px;
  color: #fff;
}

.page-our-services .page-header h2 {
  font-size: 60px;
  color: #fff;
}

.our-services_banner-01 {
  background: url("../images/our-services/our-services_section-02_bg.jpg") top left;
  height: 700px;
  position: relative;
}

.our-services_banner-01_wrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
}

.our-services_banner-01 h2 {
  color: #fff;
  text-transform: inherit;
  font-size: 60px;
  margin-top: 50px;
  margin-bottom: 35px;
}

@media (max-width: 63.9em) {
  .our-services_banner-01 h2 {
    font-size: 40px;
  }
}

.our-services_banner-01 h2 span {
  color: #00aeef;
}

.our-services_banner-01 p {
  color: #fff;
  max-width: 700px;
  margin: 0 auto;
}

.work-process_item {
  padding-left: 80px;
  padding-top: 50px;
  margin-bottom: 30px;
}

.work-process_item-number {
  font-size: 48px;
  font-weight: 700;
  color: #00aeef;
  width: 114px;
  height: 114px;
  border: 1px solid #eee;
  display: block;
  text-align: center;
  line-height: 114px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.work-process_item-title {
  margin-bottom: 20px;
  display: inline-block;
}

@media (min-width: 64em) {
  .work-process_item {
    overflow: hidden;
  }
  .work-process_item-title {
    position: relative;
  }
  .work-process_item-title:before {
    content: '';
    width: 200px;
    height: 1px;
    background: #ddd;
    display: block;
    position: absolute;
    top: 50%;
    right: -230px;
  }
  .work-process_item-wrapper:last-child .work-process_item-title:before {
    display: none;
  }
}

/*---------------------------
	06-07. Our Team
---------------------------*/
.our-team_job {
  text-transform: uppercase;
  font-weight: 500;
}

.our-team_img {
  margin-bottom: 30px;
  position: relative;
  z-index: 9999;
}

.our-team_twitter {
  padding: 50px 35px;
  margin-bottom: 30px;
  background: #0076a3;
  color: #fff;
}

@media (min-width: 64em) {
  .our-team_twitter {
    width: 300px;
  }
}

.our-team_twitter .fa {
  font-size: 40px;
  margin-bottom: 15px;
}

.our-team_twitter p {
  color: #fff;
  margin-bottom: 15px;
}

.our-team_instagram {
  position: relative;
  margin-bottom: 30px;
}

.our-team_instagram .fa-instagram {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 36px;
  color: #fff;
}

.our-team_info {
  margin: 65px 0px 60px 80px;
}

.our-team_name {
  text-transform: inherit;
  margin-bottom: 10px;
  position: relative;
}

.our-team_name:before {
  content: '';
  width: 200px;
  height: 1px;
  background: #ddd;
  position: absolute;
  top: 50%;
  left: -215px;
}

.our-team_story {
  background: #000;
  padding: 50px 35px 20px;
  margin-bottom: 30px;
}

.our-team_story h5 {
  color: #00aeef;
  margin-bottom: 35px;
}

.our-team_story p {
  color: #fff;
  margin-bottom: 30px;
}

.our-team_story-signature {
  float: right;
}

.social-link {
  margin-bottom: 30px;
}

.social-link li {
  padding-right: 0;
}

.social-link a {
  font-size: 16px;
  color: #111;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.social-link a:hover {
  color: #fff;
  background: #00aeef;
  border-color: #00aeef;
}

@media (min-width: 48em) {
  .our-team_story {
    margin-top: 180px;
  }
  .our-team_info-wrapper {
    position: relative;
  }
  .our-team_info {
    margin: 0;
    position: absolute;
    top: 60px;
    left: calc(100% + 80px);
    min-width: 300px;
  }
  .our-team_item-reverse .our-team_info {
    left: auto;
    right: calc(100% + 80px);
  }
  .our-team_item-reverse .our-team_name:before {
    left: auto;
    right: -215px;
  }
}

/*---------------------------
	06-08. Packages Pricing
---------------------------*/
/* PAGE-PACKAGES-AND-PRICING */
.page-packages-and-pricing .page-header,
.page-pricing-tables .page-header {
  height: 400px;
  position: relative;
  background: url("../images/packages-pricing_bg.jpg") top left;
  background-size: cover;
}

.page-packages-and-pricing .page-header:before,
.page-pricing-tables .page-header:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.85;
}

.page-packages-and-pricing .page-header_content,
.page-pricing-tables .page-header_content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
}

.page-packages-and-pricing .page-header h2,
.page-pricing-tables .page-header h2 {
  margin-top: 35px;
  margin-bottom: 25px;
  color: #fff;
}

.packages-pricing-table thead {
  background: #fcfcfc;
}

.packages-pricing-table thead * {
  text-align: center;
}

.packages-pricing-table .table > thead > tr > th {
  border-bottom: 1px solid #eee;
  vertical-align: middle;
  padding: 0;
}

.packages-pricing-table .table > thead > tr > th:first-child {
  border-left: 1px solid #eee;
}

.packages-pricing-table .table > thead:first-child > tr:first-child > th {
  border-top: 1px solid #111;
}

.packages-pricing-table .table > thead:first-child > tr:first-child > th.standard {
  border-top-color: #333;
}

.packages-pricing-table .table > thead:first-child > tr:first-child > th.professional {
  border-top-color: #0076a3;
}

.packages-pricing-table .table > thead:first-child > tr:first-child > th.custom {
  border-top-color: #00aeef;
}

.packages-pricing-table_title {
  background: #fff;
  color: #111;
  font-size: 12px;
  font-weight: 500;
  padding: 20px 30px;
  border: 1px solid #eee;
}

.packages-pricing-table_price {
  font-size: 32px;
  font-weight: 300;
  color: #fff;
  background: #111;
  padding: 30px;
}

.packages-pricing-table_price.standard {
  background: #333;
}

.packages-pricing-table_price.professional {
  background: #0076a3;
}

.packages-pricing-table_price.custom {
  background: #00aeef;
}

.packages-pricing-table tbody > tr > td {
  text-align: center;
  padding: 20px 30px;
}

.packages-pricing-table tbody > tr > td:first-child {
  text-align: left;
}

.packages-pricing-table .table > tbody > tr > td {
  border: 1px solid #eee;
}

.packages-pricing-table .table-striped > tbody > tr:nth-of-type(odd) {
  background: #f8f8f8;
}

.packages-pricing-table .table-striped > tbody > tr:nth-of-type(even) {
  background: #fcfcfc;
}

.packages-pricing-table .fa-check {
  color: #59b200;
  font-size: 18px;
}

.packages-pricing-table .fa-times {
  color: #d80000;
  font-size: 18px;
}

.packages-pricing-table tfoot {
  text-align: center;
}

.packages-pricing-table .table > tfoot > tr > td {
  padding: 30px;
  background: #fcfcfc;
  border: 1px solid #eee;
}

.packages-pricing-table .table > tfoot > tr > td:first-child {
  background: transparent;
  border: none;
}

@media (max-width: 79.9em) {
  .packages-pricing-table {
    overflow-x: auto;
  }
}

/* PRICING-TABLES */
.pricing-tables.packages-pricing-table tbody > tr > td:first-child {
  text-align: center;
}

.pricing-tables.packages-pricing-table .table > tfoot > tr > td:first-child {
  background: #fcfcfc;
  border: 1px solid #eee;
}

.pricing-tables th.professional h5 {
  background: #f5f5f5;
}

.pricing-tables.packages-pricing-table .table-striped > tbody > tr:nth-of-type(odd) {
  background: transparent;
}

.pricing-tables tbody .pro, .pricing-tables.packages-pricing-table .table > tfoot > tr > td.pro {
  background: #f7f7f7;
}

/*---------------------------
	06-09. Portfolio
---------------------------*/
/* PORTFOLIO BUTTON FILTER */
.folio-main-filter,
.folio-carousel-filter {
  text-align: center;
}

.folio-main-filter li,
.folio-carousel-filter li {
  padding: 0px 15px;
}

.folio-main-filter a,
.folio-carousel-filter a {
  color: #111;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

/* FOLIO-CAROUSEL */
.carousel-main,
.carousel-main-4col {
  margin-bottom: 0;
}

.carousel-main img,
.carousel-main-4col img {
  width: 100%;
}

.carousel-main .slick-prev:before, .carousel-main .slick-next:before,
.carousel-main-4col .slick-prev:before,
.carousel-main-4col .slick-next:before {
  font-size: 48px;
  opacity: 1;
}

.carousel-main .slick-prev:before,
.carousel-main-4col .slick-prev:before {
  content: '\e686';
}

.carousel-main .slick-next:before,
.carousel-main-4col .slick-next:before {
  content: '\e684';
}

.carousel-main .slick-prev, .carousel-main .slick-next,
.carousel-main-4col .slick-prev,
.carousel-main-4col .slick-next {
  width: 50px;
  height: 80px;
  background: #111;
  z-index: 999;
}

.carousel-main .slick-prev:hover, .carousel-main .slick-next:hover,
.carousel-main-4col .slick-prev:hover,
.carousel-main-4col .slick-next:hover {
  background: #00aeef;
}

.carousel-main .slick-prev,
.carousel-main-4col .slick-prev {
  left: 0;
}

.carousel-main .slick-next,
.carousel-main-4col .slick-next {
  right: 0;
}

/* CAROUSEL-ITEM_CONTENT */
.carousel-main-item {
  position: relative;
}

.carousel-main-item:hover .carousel-main-item_content {
  opacity: 1;
  visibility: visible;
}

.carousel-main-item_content {
  opacity: 0;
  visibility: hidden;
  background: #fff;
  text-align: center;
  width: 250px;
  padding: 50px 20px 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 80em) {
  .carousel-main-item_content {
    width: 46.8%;
    min-width: 300px;
    padding: 70px 20px 60px;
  }
}

.carousel-main-item_content h3 {
  margin-top: 25px;
  margin-bottom: 20px;
}

.carousel-main-item_content h3 a {
  color: #111;
}

/* FOLIO-MAIN-GRID */
.folio-main-grid .folio-main-item {
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px;
}

@media (min-width: 48em) {
  .folio-main-grid .folio-main-item {
    width: 50%;
  }
}

@media (min-width: 64em) {
  .folio-main-grid .folio-main-item {
    width: 25%;
  }
}

.folio-main-grid .folio-main-item img {
  width: 100%;
}

@media (min-width: 48em) {
  .folio-main-grid.folio-main-2col .folio-main-item {
    width: 50%;
  }
}

@media (min-width: 48em) {
  .folio-main-grid.folio-main-4col .folio-main-item {
    width: 50%;
  }
}

@media (min-width: 64em) {
  .folio-main-grid.folio-main-4col .folio-main-item {
    width: 25%;
  }
}

.folio-main-grid.folio-main-4col-3inrow .folio-main-item_sizer.folio-main-item {
  margin-bottom: 0;
}

@media (min-width: 48em) {
  .folio-main-grid.folio-main-4col-3inrow .folio-main-item_sizer,
  .folio-main-grid.folio-main-4col-3inrow .folio-main-item {
    width: 50%;
  }
}

@media (min-width: 64em) {
  .folio-main-grid.folio-main-4col-3inrow .folio-main-item_sizer,
  .folio-main-grid.folio-main-4col-3inrow .folio-main-item {
    width: 25%;
  }
}

@media (min-width: 48em) {
  .folio-main-grid.folio-main-4col-3inrow .folio-main-item_width2 {
    width: 100%;
  }
}

@media (min-width: 64em) {
  .folio-main-grid.folio-main-4col-3inrow .folio-main-item_width2 {
    width: 50%;
  }
}

@media (min-width: 48em) {
  .folio-main-grid.folio-main-3col .folio-main-item, .folio-main-grid.folio-main-3col-nopadding .folio-main-item {
    width: 50%;
  }
}

@media (min-width: 64em) {
  .folio-main-grid.folio-main-3col .folio-main-item, .folio-main-grid.folio-main-3col-nopadding .folio-main-item {
    width: calc(100%/3);
  }
}

.folio-main-grid.folio-main-3col-nopadding .folio-main-item {
  padding: 0;
  margin-bottom: 0px;
}

.folio-main-grid.folio-main-5col .folio-main-item_sizer.folio-main-item {
  margin-bottom: 0;
}

@media (min-width: 48em) {
  .folio-main-grid.folio-main-5col .folio-main-item_sizer,
  .folio-main-grid.folio-main-5col .folio-main-item {
    width: 25%;
  }
}

@media (min-width: 64em) {
  .folio-main-grid.folio-main-5col .folio-main-item_sizer,
  .folio-main-grid.folio-main-5col .folio-main-item {
    width: 20%;
  }
}

@media (min-width: 48em) {
  .folio-main-grid.folio-main-5col .folio-main-item_width2 {
    width: 50%;
  }
}

@media (min-width: 64em) {
  .folio-main-grid.folio-main-5col .folio-main-item_width2 {
    width: 40%;
  }
}

.carousel-main-4col {
  padding-left: 15px;
  padding-right: 15px;
}

.carousel-main-4col .carousel-main-item {
  padding-left: 15px;
  padding-right: 15px;
}

/* PORTFOLIO ITEM */
.carousel-main-item .folio-wrapper,
.folio-main-item .folio-wrapper {
  position: relative;
}

.carousel-main-item:hover .folio-overlay,
.carousel-main-item:hover .folio-overlay-inner,
.carousel-main-item:hover .folio-overlay-zoom,
.folio-main-item:hover .folio-overlay,
.folio-main-item:hover .folio-overlay-inner,
.folio-main-item:hover .folio-overlay-zoom {
  opacity: 1;
  visibility: visible;
}

.carousel-main-item:hover .folio-overlay-inner,
.folio-main-item:hover .folio-overlay-inner {
  top: 50px;
}

.carousel-main-item:hover .folio-overlay-zoom,
.folio-main-item:hover .folio-overlay-zoom {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.folio-overlay,
.folio-overlay-inner,
.folio-overlay-zoom {
  opacity: 0;
  visibility: hidden;
}

.folio-overlay {
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.folio-overlay h5 {
  color: #fff;
}

.folio-overlay p {
  margin-bottom: 5px;
}

.folio-overlay-inner {
  position: absolute;
  top: 0px;
  left: 50px;
}

.folio-overlay-zoom {
  position: absolute;
  bottom: 50px;
  left: 50px;
  font-size: 30px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

/* PORTFOLIO DETAIL */
.page-portfolio-detail .page-header {
  background: url("../images/portfolio/portfolio-detail_bg.jpg") top left;
  background-size: cover;
}

@media (min-width: 80em) {
  .page-portfolio-detail .page-header {
    height: 800px;
    position: relative;
  }
  .page-portfolio-detail .page-header .page-header_content {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 100%;
  }
}

.page-portfolio-detail .page-header_content-inner {
  background: #fff;
  margin: 0 auto;
  padding: 75px 20px;
}

@media (min-width: 64em) {
  .page-portfolio-detail .page-header_content-inner {
    width: 600px;
    margin: 100px auto;
  }
}

.portfolio-metadata > div {
  margin-bottom: 20px;
}

.portfolio-metadata h6 {
  display: inline-block;
  margin-right: 8px;
}

.portfolio-metadata .metadata {
  display: inline-block;
}

.portfolio-metadata .metadata span {
  margin-right: 10px;
}

.folio-item {
  position: relative;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

.folio-item.col-3 {
  width: calc(100%/3);
}

@media (max-width: 47.9em) {
  .folio-item.col-3 {
    width: calc(100%/2);
  }
}

.folio-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  visibility: hidden;
}

.folio-item svg {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  opacity: 0;
  visibility: hidden;
}

.folio-item:hover:before {
  opacity: 1;
  visibility: visible;
}

.folio-item:hover svg {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

/*---------------------------
	06-10. Shop
---------------------------*/
/* SHOP HEADER */
.page-shop .page-header {
  height: 480px;
  background: url("../images/shop/shop-big-title_bg.jpg") center center;
  background-size: cover;
  position: relative;
}

.page-shop .page-header_content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
}

.page-shop .page-header h2 {
  margin: 35px 0px 25px;
}

/* SHOP FILTER */
.shop-filter select {
  height: 42px;
  padding: 5px 20px;
  margin-right: 6px;
}

@media (min-width: 48em) {
  .shop-filter select {
    width: calc(100%/3 - 10px);
  }
}

@media (min-width: 80em) {
  .page-shop.shop-fullwidth .shop-filter select {
    width: 270px;
  }
  .page-shop.shop-sidebar .shop-filter select {
    width: 180px;
  }
}

/* GRID PRODUCT */
.product:hover .product-link:before {
  height: 100%;
  opacity: 0.95;
}

.product:hover .product-button {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.image-product-contain {
  position: relative;
}

.product-link:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #fff;
  opacity: 0;
}

.onsale {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  background: #e95249;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
}

.product-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 100%;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  margin-top: -100px;
}

.add-to-cart-button,
.product-review {
  display: block;
  width: 172px;
  margin: 0 auto 10px;
  padding: 11px 15px;
}

.add-to-cart-button span,
.product-review span {
  font-size: 16px;
  vertical-align: middle;
  margin-left: 5px;
}

.woo-content-product {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 40px;
}

.product-name {
  color: #111;
  text-transform: uppercase;
  font-weight: 500;
}

.price ins {
  text-decoration: none;
}

.amount {
  font-weight: 500;
  color: #00aeef;
}

ins .amount {
  color: #e95249;
}

del .amount {
  color: #999;
}

.star-rating {
  position: relative;
  width: 5em;
  height: 15px;
  overflow: hidden;
  line-height: 1;
  margin: 5px auto;
}

.sidebar .star-rating,
.product-info .star-rating {
  margin: 0;
}

.star-rating:before {
  content: "\f005\f005\f005\f005";
  color: #00aeef;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.star-rating span:before {
  content: "\f005\f005\f005\f005\f005";
  color: #eee;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* PRODUCT DETAIL */
.images-product-wrapper {
  position: relative;
}

.lSSlideOuter .lSPager.lSGallery {
  margin: 0 auto;
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #00aeef;
}

.lg-outer .lg-thumb-item {
  border-radius: 0;
}

.product-title {
  margin-top: 30px;
  margin-bottom: 25px;
}

.input-quantity {
  width: 80px;
  height: 40px;
  margin-right: 16px;
  margin-bottom: 0;
  padding: 0px 0px 0px 20px;
  border-radius: 0;
}

.bttn-cart {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.bttn-cart span {
  font-size: 16px;
  vertical-align: middle;
  margin-left: 5px;
}

.product-meta > div {
  margin-bottom: 30px;
}

.product-meta h6 {
  display: inline-block;
  margin-right: 5px;
}

.product-meta .share {
  display: inline-block;
}

.product-meta .share span {
  margin-right: 10px;
}

.shop-content-tabs .nav-tabs > li > a {
  border: 1px solid #ddd;
  border-left: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #111;
  border-radius: 0;
  margin-right: 0px;
  padding: 15px 40px;
}

.shop-content-tabs .nav-tabs > li:first-child > a {
  border-left: 1px solid #ddd;
}

.shop-content-tabs .nav-tabs > li.active > a, .shop-content-tabs .nav-tabs > li.active > a:focus, .shop-content-tabs .nav-tabs > li.active > a:hover, .shop-content-tabs .nav-tabs > li > a:hover {
  color: #fff;
  background-color: #00aeef;
  border-color: #00aeef;
}

@media (max-width: 47.9em) {
  .shop-content-tabs .nav-tabs > li {
    width: 100%;
  }
  .shop-content-tabs .nav-tabs > li > a {
    border-left: 1px solid #ddd;
  }
}

.shop-content-tabs .tab-content {
  border: 1px solid #ddd;
  margin-top: -1px;
  padding: 40px;
}

/* SHOPPING CART */
.product-thumbnail {
  min-width: 85px;
}

.product-thumbnail_img {
  width: 70px;
}

.cart-actions .bttn {
  width: auto;
  margin-bottom: 0;
  margin-left: 10px;
}

/* CHECKOUT */
.checkout-login .form-group,
.checkout-coupon .form-group {
  padding: 20px 20px 10px 20px;
  border: 1px solid #ddd;
}

.rememberme {
  margin-top: 10px;
  margin-left: 5px;
}

.checkout-payment input[type="radio"] {
  vertical-align: middle;
  margin-bottom: 5px;
}

.checkout-payment-paypal-img {
  width: 150px;
}

.place-order label, .place-order input {
  vertical-align: middle;
  margin-bottom: 10px;
}

.place-order input {
  margin-bottom: 15px;
}

/* SHOP BANNER */
.shop-banner {
  overflow: hidden;
}

.shop-banner:before {
  background: #fff;
  content: "";
  height: 555px;
  left: -230px;
  opacity: .2;
  position: absolute;
  top: -180px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 80px;
}

.shop-banner:hover:before {
  left: 120%;
}

.shop-banner:hover h3 {
  letter-spacing: 0.16em;
}

/*---------------------------
	06-11. Custom Class
---------------------------*/
.text-inherit {
  text-transform: inherit;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media (min-width: 48em) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-right {
    text-align: right;
  }
}

@media (min-width: 64em) {
  .text-md-left {
    text-align: left;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-right {
    text-align: right;
  }
}

@media (min-width: 80em) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-center {
    text-align: center;
  }
  .text-lg-right {
    text-align: right;
  }
}

.padding-0 {
  padding: 0;
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-x-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.padding-x-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-y-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-y-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-y-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-y-130 {
  padding-top: 130px;
  padding-bottom: 130px;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-left-70 {
  padding-left: 70px;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-top-120 {
  padding-top: 120px;
}

.padding-top-130 {
  padding-top: 130px;
}

.padding-top-150 {
  padding-top: 150px;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-bottom-80 {
  padding-bottom: 80px;
}

.padding-bottom-90 {
  padding-bottom: 80px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

@media (min-width: 48em) {
  .padding-0-sm {
    padding: 0;
  }
  .padding-x-0-sm {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-x-30-sm {
    padding-left: 30px;
    padding-right: 30px;
  }
  .padding-x-70-sm {
    padding-left: 70px;
    padding-right: 70px;
  }
  .padding-top-60-sm {
    padding-top: 60px;
  }
  .padding-y-0-sm {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-y-100-sm {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .padding-left-0-sm {
    padding-left: 0;
  }
  .padding-left-30-sm {
    padding-left: 30px;
  }
  .padding-left-70sm {
    padding-left: 70px;
  }
  .padding-right-0-sm {
    padding-right: 0;
  }
  .padding-right-30-sm {
    padding-right: 30px;
  }
}

@media (min-width: 64em) {
  .padding-0-md {
    padding: 0;
  }
  .padding-x-0-md {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-x-30-md {
    padding-left: 30px;
    padding-right: 30px;
  }
  .padding-x-70-md {
    padding-left: 70px;
    padding-right: 70px;
  }
  .padding-y-0-md {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-y-100-md {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .padding-top-0-md {
    padding-top: 0;
  }
  .padding-top-20-md {
    padding-top: 20px;
  }
  .padding-top-60-md {
    padding-top: 60px;
  }
  .padding-top-100-md {
    padding-top: 100px;
  }
  .padding-top-120-md {
    padding-top: 120px;
  }
  .padding-top-130-md {
    padding-top: 130px;
  }
  .padding-top-150-md {
    padding-top: 150px;
  }
  .padding-left-0-md {
    padding-left: 0;
  }
  .padding-left-30-md {
    padding-left: 30px;
  }
  .padding-left-70-md {
    padding-left: 70px;
  }
  .padding-right-0-md {
    padding-right: 0;
  }
  .padding-right-30-md {
    padding-right: 30px;
  }
  .padding-right-70-md {
    padding-right: 70px;
  }
  .padding-bottom-70-md {
    padding-bottom: 70px;
  }
}

@media (min-width: 80em) {
  .padding-0-lg {
    padding: 0;
  }
  .padding-x-0-lg {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-x-30-lg {
    padding-left: 30px;
    padding-right: 30px;
  }
  .padding-x-50-lg {
    padding-left: 50px;
    padding-right: 50px;
  }
  .padding-x-70-lg {
    padding-left: 70px;
    padding-right: 70px;
  }
  .padding-x-200-lg {
    padding-left: 200px;
    padding-right: 200px;
  }
  .padding-y-0-lg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-y-100-lg {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .padding-left-0-lg {
    padding-left: 0;
  }
  .padding-left-30-lg {
    padding-left: 30px;
  }
  .padding-left-70-lg {
    padding-left: 70px;
  }
  .padding-left-120-lg {
    padding-left: 120px;
  }
  .padding-right-0-lg {
    padding-right: 0;
  }
  .padding-right-30-lg {
    padding-right: 30px;
  }
  .padding-right-70-lg {
    padding-right: 70px;
  }
}

.margin-0 {
  margin: 0;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-y-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.margin-y-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.margin-y-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.margin-y-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-bottom-90 {
  margin-bottom: 90px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-bottom-120 {
  margin-bottom: 120px;
}

.margin-bottom-150 {
  margin-bottom: 120px;
}

@media (min-width: 64em) {
  .margin-bottom-0-md {
    margin-bottom: 0;
  }
  .margin-bottom-70-md {
    margin-bottom: 70px;
  }
  .margin-bottom-150-md {
    margin-bottom: 120px;
  }
}

@media (min-width: 48em) {
  .pull-left-sm {
    float: left;
  }
  .pull-right-sm {
    float: right;
  }
}

@media (min-width: 64em) {
  .pull-left-md {
    float: left;
  }
  .pull-right-md {
    float: right;
  }
}

@media (min-width: 80em) {
  .pull-left-lg {
    float: left;
  }
  .pull-right-lg {
    float: right;
  }
}

.width-auto {
  width: auto;
}

.bg-light-gray {
  background: #fbfbfb;
}

.bg-eee {
  background: #eee;
}

.bg-f8f8f8 {
  background: #f8f8f8;
}

.bg-f3f3f3 {
  background: #f3f3f3;
}

.bg-111 {
  background: #111111;
}

.bg-333 {
  background: #333333;
}

.bg-primary-color {
  background: #00aeef;
}

.bg-secondary-color {
  background: #0076a3;
}

.primary-color {
  color: #00aeef;
}

.price-red-color {
  color: #e95249;
}

.white-color {
  color: #fff;
}

.fz-20 {
  font-size: 20px;
  font-weight: 300;
}

.img-fullwidth {
  width: 100%;
  height: auto;
}
