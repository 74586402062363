/*------------------------------------------------------------------
Theme Name: Dione
Version: 1.0
Author:
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]

## 01. GLOBAL STYLES
   01-01. Normalize
   01-02. Typography
   01-03. Custom class

## 02. COMBO CSS

## 03. NAVIGATION
   03-01. Menus
   03-02. Mobile menu
   03-03. Social menu
   03-04. Breadcrumb

## 04. LAYOUT
   04-01. Header
   04-02. Footer
   04-03. Widget, sidebar
   04-04. Mini cart

## 05. COMPONENTS
   05-01. Accordion
   05-02. Alerts
   05-03. Buttons
   05-04. Call to action
   05-05. Carousels
   05-06. Charts
   05-07. Collapse
   05-08. Countdown Clock
   05-09. Counters
   05-10. Dividers
   05-11. Drop caps
   05-12. Forms
   05-13. Gallery
   05-14. Icon Boxes
   05-15. Lists
   05-16. Pagination
   05-17. Scrollup
   05-18. Slider
   05-19. Table
   05-20. Tabs
   05-21. Testimonials
   05-22. Text Block
   05-23. Title Background
   05-24. Typography

## 06. CONTENT
   06-01. About
   06-02. Blog
   06-03. Contact
   06-04. Elements
   06-05. Home
   06-06. Our Services
   06-07. Our Team
   06-08. Packages Pricing
   06-09. Portfolio
   06-10. Shop
   06-11. Custom Class
-------------------------------------------------------------------*/
@import "vendors/vendors";
@import "utils/variables";
@import "utils/mixins";
/*==============================================================
	01. GLOBAL STYLES
==============================================================*/
@import "base/_base";
/*==============================================================
	02. COMBO CSS
==============================================================*/
@import "utils/placeholders";
/*==============================================================
	03. NAVIGATION
==============================================================*/
@import "navigation/navigation";
/*==============================================================
	04. LAYOUT
==============================================================*/
@import "layout/layout";
/*==============================================================
	05. COMPONENTS
==============================================================*/
@import "components/components";
/*==============================================================
	06. CONTENT
==============================================================*/
@import "pages/pages";
@import "utils/custom-class";
