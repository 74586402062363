/* SHOP HEADER */
.page-shop {
    .page-header {
        height: 480px;
        background: url("../images/shop/shop-big-title_bg.jpg") center center;
        background-size: cover;
        position: relative;
        &_content {
            @include center-y;
            width: 100%;
        }
        h2 {
            margin: 35px 0px 25px;
        }
    }
}

/* SHOP FILTER */
.shop-filter {
    select {
        height: 42px;
        padding: 5px 20px;
        margin-right: 6px;
    }
    @include media-breakpoint-up(sm) {
        select {
            width: calc(100%/3 - 10px);
        }
    }

    @include media-breakpoint-up(lg) {
        .page-shop.shop-fullwidth & {
            select {
                width: 270px;
            }
        }
        .page-shop.shop-sidebar & {
            select {
                width: 180px;
            }
        }
    }
}

/* GRID PRODUCT */
.product {
    &:hover {
        .product-link:before {
            height: 100%;
            opacity: 0.95;
        }
        .product-button {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
        }
    }
}

.image-product-contain {
    position: relative;
}
.product-link:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: #fff;
    opacity: 0;
    @extend %transit;
}

.onsale {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    background: $sale-color;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
}

.product-button {
    @include center-y;
    @extend %transit;
    width: 100%;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    margin-top: -100px;
}
.add-to-cart-button,
.product-review {
    display: block;
    width: 172px;
    margin: 0 auto 10px;
    padding: 11px 15px;
    span {
        font-size: 16px;
        vertical-align: middle;
        margin-left: 5px;
    }
}

.woo-content-product {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 40px;
}
.product-name {
    color: $black-color;
    text-transform: uppercase;
    font-weight: 500;
}

.price {
    ins {
        text-decoration: none;
    }
}
.amount {
    font-weight: 500;
    color: $primary-color;
    ins & {
        color: $sale-color;
    }
    del & {
        color: $gray-color;
    }
}

.star-rating {
    position: relative;
    width: 5em;
    height: 15px;
    overflow: hidden;
    line-height: 1;
    margin: 5px auto;
    .sidebar &,
    .product-info & {
        margin: 0;
    }
    &:before {
        @extend %icon;
        content: "\f005\f005\f005\f005";
        color: $primary-color;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    span:before {
        @extend %icon;
        content: "\f005\f005\f005\f005\f005";
        color: #eee;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

/* PRODUCT DETAIL */
.images-product-wrapper {
    position: relative;
}

.lSSlideOuter .lSPager.lSGallery {
    margin: 0 auto;
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: $primary-color;
}
.lg-outer .lg-thumb-item {
    border-radius: 0;
}

.product-title {
    margin-top: 30px;
    margin-bottom: 25px;
}

.input-quantity {
    width: 80px;
    height: 40px;
    margin-right: 16px;
    margin-bottom: 0;
    padding: 0px 0px 0px 20px;
    border-radius: 0;
}

.bttn-cart {
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    border-radius:5px;
    span {
        font-size: 16px;
        vertical-align: middle;
        margin-left: 5px;
    }
}

.product-meta {
    & > div {
        margin-bottom: 30px;
    }
    h6 {
        display: inline-block;
        margin-right: 5px;
    }
    .share {
        display: inline-block;
        span {
            margin-right: 10px;
        }
    }
}

.shop-content-tabs {
    .nav-tabs > li > a {
        border: 1px solid #ddd;
        border-left: 0;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        color: $black-color;
        border-radius: 0;
        margin-right: 0px;
        padding: 15px 40px;
    }
    .nav-tabs > li:first-child > a {
        border-left: 1px solid #ddd;
    }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover, .nav-tabs > li > a:hover {
        color: $white-color;
        background-color: $primary-color;
        border-color: $primary-color;
    }
    @include media-breakpoint-down(xs) {
        .nav-tabs > li {
            width: 100%;
        }
        .nav-tabs > li > a {
            border-left: 1px solid #ddd;
        }
    }

    .tab-content {
        border: 1px solid #ddd;
        margin-top: -1px;
        padding: 40px;
    }
}

/* SHOPPING CART */
.product-thumbnail {
    min-width: 85px;
}
.product-thumbnail_img {
    width: 70px;
}

.cart-actions {
    .bttn {
        width: auto;
        margin-bottom: 0;
        margin-left: 10px;
    }
}

/* CHECKOUT */
.checkout-login,
.checkout-coupon {
    .form-group {
        padding: 20px 20px 10px 20px;
        border: 1px solid #ddd;
    }
}

.rememberme {
    margin-top: 10px;
    margin-left: 5px;
}

.checkout-payment {
    input[type="radio"] {
        vertical-align: middle;
        margin-bottom: 5px;
    }
    &-paypal-img {
        width: 150px;
    }
}
.place-order {
    label, input {
        vertical-align: middle;
        margin-bottom: 10px;
    }
    input {
        margin-bottom: 15px;
    }
}

/* SHOP BANNER */
.shop-banner {
    overflow: hidden;
    img {
        @extend %transit;
    }
    h3 {
        @extend %transit;
    }
    &:before {
        background: #fff;
        content: "";
        height: 555px;
        left: -230px;
        opacity: .2;
        position: absolute;
        top: -180px;
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
        -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 80px;
    }
    &:hover {
        &:before {
            left: 120%;
        }
        h3 {
            letter-spacing: 0.16em;
        }
    }
}
