.pagination-dn {
    text-align: center;
}
.page-numbers li {
    display: inline-block;
    margin: 0px 2px 10px;
    .next, .prev {
        text-transform: uppercase;
        color: $black-color;
        font-weight: 500;
        font-size: 12px;
        .fa {
            font-size: 14px;
            margin-right: 5px;
            margin-left: 5px;
        }
        &:hover {
            color: $primary-color;
        }
    }
    @include media-breakpoint-down(xs) {
        &.next, &.prev {
            display: block;
        }
    }
    @include media-breakpoint-up(sm) {
        .prev {
            margin-right: 10px;
        }
        .next {
            margin-left: 10px;
        }
    }
}
.page-number {
    display: block;
    text-transform: uppercase;
    color: $black-color;
    font-weight: 500;
    border: 1px solid $black-color;
    border-radius: 4px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    &:hover, &.current {
        background: $black-color;
        color: $white-color;
    }
}
