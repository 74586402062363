.search-icon {
    font-size: 22px;
    line-height: 1;
    a {
        color: $black-color;
    }
}

.top-search {
    background: $black-color;
    overflow: hidden;
    form {
        position: relative;
        &:before {
            @extend %icon-pe-7s;
            content: "\e618";
            font-size: 18px;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -10px;
        }
        input {
            font-size: 16px;
            width: 100%;
            border: 0;
            background: transparent;
            margin-bottom: 0;
            padding: 20px 0px 20px 30px;
        }
    }
    .search-close {
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -12px;
        a {
            font-size: 24px;
            line-height: 1;
        }
    }
}
