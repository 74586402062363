.mini-cart {
    position: static;
    &_button {
        background: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        font-size: 20px;
        color: $black-color;
        position: relative;
        &:after {
            content: attr(data-count);
            font-size: 12px;
            text-align: center;
            position: absolute;
            top: -10px;
            right: -12px;
            min-width: 20px;
            min-height: 20px;
            line-height: 21px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            color: $white-color;
            background: $primary-color;
        }
    }
    .dropdown-menu {
        z-index: 99999;
        display: block;
        opacity: 0;
        visibility: hidden;
        margin-top: 40px;
        @extend %transit;
    }
    &.open .dropdown-menu {
        opacity: 1;
        visibility: visible;
        margin-top: 10px;
    }
    .cart-list {
        right: 15px;
    }
}
.mini-cart.white-style {
    .mini-cart_button {
        color: $white-color;
        border-color: $white-color;
    }
    .cart-list {
        border-top: 0
    }
}

.cart-list {
    width: 300px;
    border-top: 2px solid $black-color;
    border-radius: 0;
    padding: 0;
    &_item {
        padding: 15px;
        border-bottom: 1px solid #eee;
    }
    .product-img, .product-content {
        display: table-cell;
        vertical-align: middle;
    }
    .product-img img {
        width: 70px;
    }
    .product-content {
        padding-left: 15px;
    }

    .product-name {
        display: block;
        text-transform: capitalize;
    }
    .total {
        color: $black-color;
        font-weight: 500;
    }
    .cart-list-btns {
        text-align: center;
    }
    .cart-btn {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        border: 1px solid $black-color;
        border-radius: 4px;
        padding: 8px 20px;
        &:hover {
            color: #fff;
            background: $black-color;
        }
    }
}

@include media-breakpoint-down(xs) {
    .cart-list {
        width: 290px;
    }
}

@include media-breakpoint-up(lg) {
    .mini-cart {
        position: relative;
        .cart-list {
            right: 0;
        }
    }
}
